<!--
 * @Author: your name
 * @Date: 2023-03-06 18:06:16
 * @LastEditTime: 2023-03-06 18:07:29
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \saic-design-web\src\App.vue
-->
<template>
  <RouterView />
</template>

<script setup>
let image = require("./assets/loading.png");
import { useRouter } from "vue-router";
import { h, provide, readonly, onBeforeUnmount } from "vue";
const indicator = h("div", { class: "loading-box", spin: true }, [
  h("img", { src: image }),
]);
const router = useRouter()
provide("indicator", readonly(indicator));

onBeforeUnmount(() => {
  window.removeEventlistener("scroll", () => {}, false);
});
</script>
<style>
@import "@/assets/baseStyle/base.css";
</style>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "AalibabaPuHui-A";
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
ul,
li {
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 0 !important;
}

.ant-tooltip-arrow-content {
  margin-bottom: 4px !important;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfdfdf;
}

.loading-wrapper .loading-image {
  width: 320px;
  opacity: 0.2;
  animation: loading 1.2s ease-in infinite forwards;
}
@keyframes loading {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
