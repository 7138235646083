export default {
    // 首页 start
    enterEurope: '访问欧洲',
    AboutUs: '关于我们',
    Team: '团队',
    Works: '作品',
    SDCMegagame: 'SDC大赛',
    Chinese: '中文',
    English: '英文',
    Propaganda: '上汽大赛宣传语',
    OurStory: '我们的故事',
    StoryDesc: '上汽设计中心（SAIC DESIGN）是上汽集团创新研究开发总院下属设计中心，主要负责上汽集团旗下荣威、MG和飞凡等品牌的汽车设计重任。\n\n自成立以来，历经十余年的快速发展，已成长为中国汽车设计领域最重要的力量之一，并于2021年正式成为国家级工业设计中心。',
    OfficialWeibo: '官方微博',
    OfficialWeChat: '官方微信',
    OfficialINS: '官网INS',
    OurTeam: '我们的团队',
    TeamIntro: '作为亚洲规模最大的汽车设计中心之一，汇聚了来自全球各地逾300名心怀汽车设计梦想的优秀设计人才，以及多位国际顶级设计专家，配备有国际一流的软硬件设施和造型开发设备。团队拥有从用户研究、前瞻设计，到整车设计的全产业链开发能力，同时向品牌设计，服务设计等整合创新设计领域持续拓展。上汽设计全球团队立足于中国，整合全球资源，今后还将不断地为全球市场打造更多优秀的汽车设计作品。',
    Leader: '邵景峰',
    NameIntro: '上汽集团创新研究开发院副院长、总设计师、上汽英国技术中心总经理\n\n2011年10月起，邵景峰就职上汽集团技术中心，负责上汽集团旗下荣威、MG、飞凡等自主品牌的造型设计工作。构建了上海设计总部、英国伦敦前瞻设计中心及其它海外设计工作室全球联动的国际化设计团队。斩获了包括德国红点、德国IF、日本G-Mark、上海青年高端创意人才奖、2021福布斯中国十大设计师奖、2021中国十大首席设计官、2021设计光华龙腾奖中国设计贡献奖银质奖章等众多国内外设计大奖。\n\n作为上海市第十二届资深青联委员、上海市工会代表，邵景峰同时还担任教育部全国专业学位水平评估专家，在同济大学、上海交大、中央美院、华东理工等高校担任兼职教授或研究生导师，自2013年起创办的上汽设计国际挑战赛，为中国汽车设计行业输送了大批优质人才。',
    Name1: '黄晴辉',
    Position1: '上汽设计中心平台设计与运营管理总监',
    Name2: '邵长山',
    Position2: '上汽设计中心荣威品牌设计总监',
    Name3: 'Martin Kropp',
    Position3: '上汽设计中心副全球总监',
    Name4: '鲁鹰',
    Position4: '上汽设计中心名爵品牌设计总监',
    Name5: 'Oleg Son',
    Position5: '上汽设计中心副全球总监',
    Name6: 'Carl-Gotham',
    Position6: '上汽设计伦敦前瞻设计中心总监',
    Name7: 'Nicola Ehrenberg-Uhlig',
    Position7: '上汽设计中心副全球总监',
    Brand: '品牌作品',
    introduction: '上汽作为中国汽车产业的排头兵，一直以来为中国汽车产业自主研发持续贡献力量，产出了众多优秀的、被市场以及用户所认可的汽车设计作品。在十余年间，荣威、MG和飞凡三个自主品牌共计产出了56个量产项目、7个概念车项目，每一个项目都极力打造集技术、质感、舒适和设计美学于一体的产品。',
    Desc: '上汽设计团队在过去十年间，设计了众多大获市场成功的产品，并且在全球工业设计界荣获了包括德国红点奖、IF设计奖、日本G-MARK设计奖等三十多项世界级工业设计大奖。',
    ABriefIntroduction: '简短的介绍简短的介绍',
    brand1: '精致优雅，始终是荣威的产品特质',
    brand1Desc: '优雅，是设计品质的外显。整车的造型语言极致舒展，轻盈流畅；减法设计让车身没有多余的元素，简洁大方。协调而富有节奏的姿态，表达由内而外的优雅,塑造由内而外的高级。	',
    brand2: '荣威：稳健的设计进化',
    brand2Desc: '在消费者相对熟悉的设计语言中，结合趋势稳步创新，不夸张、不激进、不保守。荣威D7车长4890mm，宽1872mm, 高1510mm, 轴距2810mm，以B级的车身尺寸，让D7拥有强大的气场和充足的内饰空间。',
    brand3: '纯粹之平衡·完整·温润',
    brand3Desc: '平衡：Cd = 0.23，平衡乘坐空间与续航里程的系统最优解\n\n完整：各种设计特征追求完整的形态,如前舱机盖的分缝完整切割，营造完整纯粹的感官体验\n\n温润：大巧不工，D7营造品质感不仅仅是从细节入手，而首要是从打造高质量的曲面和体量入手。圆润的形态和倒角给人浑然一体的感受',
    brand4: '精妙品质背后是极致工艺的支撑',
    brand4Desc: '百万级豪车的工艺品质、电感展翼尾灯与双五幅追风轮毂，每一个设计思考，每一个元素，都是一件作品。',
    brand5: '整体设计理念——“高能浪漫主义”美学',
    brand5Desc: 'MG Cyberster代表了MG品牌“高能浪漫主义”设计美学的开创。它由上汽伦敦前瞻设计中心与上海设计总部联手打造，是上汽设计师们探索在智能电驱时代如何传达速度激情与真实情感的大胆尝试。人们对浪漫自由的追求是永恒不变的，而自由、感性、对生命力的天然向往，正是跑车特有的魅力，也是百年MG品牌不变的精神内核。我们用自己的方式探索各种可能，勇敢地迭代进化，让人性与科技合二为一，重构一颗奔跑的智能之心。',
    brand6: '电动剪刀门·电动软顶',
    brand6Desc: '剪刀门的设计为用户带来震撼心灵的仪式感，进出更浪漫优雅。\n\n电动软顶致敬 MG 最经典的软顶敞篷历史，展现最纯粹的欧式Roadster 魅力。',
    brand7: '纯正的欧式敞篷跑车比例·扎实的跑车姿态',
    brand7Desc: 'MG Cyberster 拥有经典的两座跑车比例：修长的前舱、靠后的A柱和极致的轮身比。在全新电动架构的支持下，车头压低斜切，强烈的低趴姿态得以显现。座舱部分透气轻盈，整车轮廓简洁顺畅。\n\n姿态代表了一辆车三维形态的整体感知，对于跑车尤为重要。宽大的四轮撑满轮拱，配合挺拔的车肩和独特的收腰处理，构建强劲的扎地感。犹如匍匐着的猫科动物，后肢下压蹬地，时刻准备前冲。',
    brand8: '“势能线”设计  Momentum Line',
    brand8Desc1: '豪华｜优雅',
    brand8Desc2: '饱满的形面带来现代豪华感，两端舒展的线形又增添了一丝优雅的气息。双拱式的腰线设计结合饱满挺拔的侧面造型，增加了整车的豪华感，预示着纯电动车平静表面下隐藏的强劲内心。',
    brand9: '空气动力专属设计',
    brand9Desc1: '迎风刃',
    brand9Desc2: '在纯电时代需要“破风”，要高效地在气流中快速前行车头快速下压，环宇之光高度融合于形面，形成饱满有力的一体感车头结构。',
    brand9Desc3: '融合',
    brand9Desc4: '保险杠两侧自然打开，开孔与远近光一体式大灯融合，直接贯通尾部，与车身型面起承转合融为一体。有效疏导底部气流，配合更顺滑的形面，造就飞凡F7卓越的空气动力学表现。',
    brand10: '掀背式行李箱',
    brand10Desc1: '运动｜优雅｜效率',
    brand10Desc2: '流畅溜背造型结合掀背式行李箱设计，整体外轮廓修长舒展一气呵成，展现运动优雅姿态的同时，达成了空间使用效率与气动效率的最优解。尾箱与尾灯的精密匹配更体现出极致精湛的工艺之美。',
    workEles: '更多作品',
    // brandInfo: '上汽设计团队在过去十年间，设计了众多大获市场成功的产品，并且在全球工业设计界荣获了包括德国红点奖、IF设计奖、日本G-MARK设计奖等三十多项世界级工业设计大奖。',
    work1: 'MG MAZE 概念车',
    work1Desc: '来自上汽设计中心伦敦前瞻设计工作室，荣获2022红点概念设计奖',
    work2: '全新MG5',
    work2Desc: '荣获2021日本优良设计奖G-Mark',
    work3: '荣威旋转钥匙',
    work3Desc: '荣获2020 IF设计奖',
    work4: '荣威RX5',
    work4Desc: '荣获2017 IF设计奖',
    work5: '飞凡汽车R7',
    work5Desc: '极智高阶纯电SUV',
    work6: 'MG MULAN',
    work6Desc: '中国电车的欧洲销冠，创造中国汽车品牌的出海新高度',
    work7: '荣威概念车R-Aura',
    work7Desc: '荣获2020红点设计概念奖',
    work8: '荣威Marvel X',
    work8Desc: '荣获2018 G-Mark全球设计大奖',
    work9: '荣威i6',
    work9Desc: '荣获2017 G-Mark全球设计大奖',
    workName: '作品名称',
    SDC: '上汽设计国际挑战赛',
    Detail: '了解详情',
    Copyright: '©2020上汽集团版权所有',
    Adress: '沪ICP备09093484号-5',
    NetWork: '沪公网安备31011402001176号',
    Language: '语言切换',
    ContestIntro: '上汽设计国际挑战赛（SDC）的初衷是寻找汽车设计的明日之星，培养中国汽车设计的新生力量。自2013年以来，上汽设计国际挑战赛至今举办已有十届，并已成为中国最具影响力的专业汽车设计赛事之一。十年间，SDC已吸引了3558名设计专业学子参赛，吸纳了220名学员进入上汽设计实习，其中90多名后续入职上汽设计中心，成为上汽设计中心的中坚力量。\n\n同时，十年以来，已有来自10个国家的216所高校，通过上汽设计国际挑战赛的平台与上汽设计形成“产学研”合作闭环。上汽设计国际挑战赛也成为了全球设计类院校与中国汽车产业之间的稳固纽带。',
    // 首页 end
    // 大赛页面 start
    CountDown:'倒计时',
    NotSubmissionPeriodYet:'4月1日投递正式开始',
    UploadPortfolios:'上传作品',
    TheSubmissionPeriodIsOver:'投递已结束',
    SubmissionDue:'投递截止时间：6月24号晚23点59分',
    PleaseLookForwardTo:"请期待下届赛事",
    ThisTournament:'本届赛事',
    PreviousExcellentWorks:'历届优秀作品',
    TitleOfEntry:'作品名称',
    CompetitionText1:"想象是通往未来的任意门，设计是未来最好的预演。",
    CompetitionText2:"然而脱离真实的想象，只能沦为创新路途中的噪音。",
    CompetitionText3:"本届赛事，我们从商业可持续、品牌可持续和创新可持续，",
    CompetitionText4:"三个影响企业持续创新的重要因素出发，思考面向真实世界的未来想象。",
    CompetitionText5:"让我们继续拥抱理性，热爱真实，持续创新，大胆想象。",
    CompetitionText6:"",
    CompetitionText7:"",
    DesignTask:"任务书",
    Introduction:"大赛简介",
    Download:"资料下载",
    Focus:"参赛方向",
    RaceTrack:"赛道",
    HomePage:"首页",
    Back:"返回",
    StylingDesign:"造型设计（主赛道）",
    ColorMaterial:"CMF设计",
    DigitalVisualizationDesign:"数字可视化设计",
    BrandResearchDesignStrategy:"品牌研究与设计策略",
    HumanMachineInterfaceDesign:"HMI设计",
    All:"全部",
    Screen:"筛选",
    Hint:"请前往Web端上传",
    REALWORLD:"真实世界：新理性与新感动",
    CompetitionText8:"大赛这十年“不一般”！",
    CompetitionText9:"我们有过天马行空的畅想，也收获过新颖的设计理念。",
    CompetitionText10:"当下外部环境的变化，我们呼吁设计回归新理性，",
    CompetitionText11:"为真实的世界寻找事物发展的内在规律：",
    CompetitionText12:"设计要解决什么问题？什么是正确的设计？如何做正确的设计？",
    CompetitionText13:"重新出发，让我们为美好生活寻求新的理解、创造新的感动！",
    submission:"提交内容",
    CompetitionText14:"1.个人介绍：",
    CompetitionText15:"100秒自我介绍MP4视频*1，简历pdf*1。",
    CompetitionText16:"如有作品集可附带提交（不做强制要求）。",
    CompetitionText17:"2.展示板面两张：",
    CompetitionText18:"A3幅面大小[420×297mm]、JPEG格式且分辨率不低于300DPI，排版形式横向排版；内容中必须包含：草图展示，方案前后45度3D渲染效果图各一张，正视图及侧视图的车身布局(Package)一张。",
    CompetitionText19:"3.展示板还可以展示：",
    CompetitionText20:"设计意象图、车身布局、概念草图、人机工程、细节设计、文字说明等内容。（不做强制要求）",
    CompetitionText21:"4.设计作品的3D数字模型文件：",
    CompetitionText22:"可接受的软件格式包括但不限于：Alias、Pro-E、UG、Catia、 Maya、 3D Max、 Rhino、Blender。",
    RisksAndOpportunities:"人工智能的风险与机遇",
    CompetitionText23:"作品要求:",
    CompetitionText24:"生成式AI（AIGC）成为继NFT、元宇宙、Web3.0之后，又一个火出圈的风口。在此风口下，对消费端、产业端、社会端会带来哪些风险与机遇？要求观点独到、资料全面、数据真实可靠且注明来源；框架逻辑清晰、画面逻辑清晰，具有易读性和吸引力。在传达上画面具有美观性。",
    CompetitionText25:"提交内容",
    CompetitionText26:"1.个人介绍:",
    CompetitionText27:"100秒自我介绍MP4视频*1，简历pdf*1。",
    CompetitionText28:"2.研究报告:",
    CompetitionText29:"（PDF格式，单页16:9横向排版）。",
    CompetitionText30:"令人「WOW」的功能",
    CompetitionText31:"本次赛事回归理性思考，从「可用性」出发，请根据对视频《真实世界》的解读，基于选手对新能源汽车及行业的理解，以HMI为介入点，打造「有体验」的智能座舱。",
    CompetitionText32:"请阅读品牌主任务书文件，根据给出的BRIEF设计HMI解决方案。",
    CompetitionText33:"提交内容 (详细要求见任务书PDF)",
    CompetitionText34:"2.设计概念解析：",
    CompetitionText35:"PDF格式，色彩RGB，单页16:9横向排版。宽850px，高不限。包含交互概念，视觉概念，设计亮点解读。明确用车场景，展示洞察力与有效可行的解决思路，尺寸需要手机阅读舒适，比例适宜，字体清晰可读。",
    CompetitionText36:"3.UI设计效果图：",
    CompetitionText37:"jpeg格式，色彩RGB，分辨率不低于300dpi，仪表1920*720，中控1920*1080，至少3张。",
    CompetitionText38:"4.设计展示视频：",
    CompetitionText39:"mp4格式，小于500M，时长2min以内, 视频需要使观众清晰理解设计理念、设计语境及设计语言分析。",
    CompetitionText40:"5.额外展示作品：",
    CompetitionText41:"任何参赛选手想要额外上传展示的附加分内容，个人才艺/作品模型/可操作原型/appendix/微动效设计/作品相关网站…格式不限，但建议能友好查阅。",
    SubmissionSpecification:"提交规范",
    CompetitionText42:"日期+作者名称+院校+个人介绍，如：20230101_张XX_鲁美_个人介绍",
    CompetitionText43:"2.概念视频：",
    CompetitionText44:"日期+作者名称+院校+概念视频，如：20230101_张XX_鲁美_概念视频",
    CompetitionText45:"3.UI设计效果图：",
    CompetitionText46:"日期+作者名称+院校+UI+页面内容，如：20230101_张XX_央美_UI_仪表",
    CompetitionText47:"4.说明文档：",
    CompetitionText48:"日期+作者名称+院校+概念解析，如：20230101_张XX_RCA_说明文档",
    CompetitionText49:"5.额外展示作品：",
    CompetitionText50:"日期+作者名称+院校+额外作品，如：20230101_张XX_UAL_额外展示作品",
    CompetitionText51:"今日梦想家",
    CompetitionText52:"请根据对真实世界视频的解读，结合纯电车型设计发展趋势，设计创新的CMF设计方案。",
    CompetitionText53:"以家庭为中心点辐射并结合环保理念，给出亮眼的设计创新点及新材料（家庭即当下的梦想，如何让一部车Brings you happiness，plus warmness）",
    CompetitionText54:"1.方案报告",
    CompetitionText55:"主题概念阐释/目标人群分析/设计趋势分析/故事板/材质板/材质板/内外饰效果图/实物材料。",
    CompetitionText56:"2.加分项",
    CompetitionText57:"辅助展示CMF设计方案的视频等。(不做强制要求)",
    CompetitionText58:"尺寸要求",
    CompetitionText59:"1.设计方案报告：",
    CompetitionText60:"PDF格式提交，单页16:9横向排版。",
    CompetitionText61:"2.设计展示视频：",
    CompetitionText62:"MP4格式，小于500M，时长不超过2min，1920*1080。",
    CompetitionText63:"超级产品力",
    CompetitionText64:"数字可视化赛道，着重要求以创新性的思维进行思考，对往届赛事获奖作品的车型在静态视觉展示和动态效果呈现上进行再创新，突出产品造型设计美学，彰显设计魅力，可进阶或颠覆原作品的场景化定义，在不改变原造型设计的基础上对静态和动态画面进行创意的再定义，呈现出造型设计在新情景下的全新感受。",
    CompetitionText65:"通过展现汽车在不同时段和不同场景下的多样化感知与体验，对人们当下真实生活场景或未来生活方式的积极思考和探索。",
    CompetitionText66:"视频板块或渲染板块作二选一。作品内容思想健康、正能量、积极向上且有较好的精神面貌，无色情、暴力、血腥、政治等负面内容。所有素材需注明来源（原创或有使用有版权的网络素材）。",
    CompetitionText67:"1.静态渲染图",
    CompetitionText68:"静态图渲染（数量9张以上，尺寸3840*2160，比例16：9，格式png或者jpeg）",
    CompetitionText69:"2.动画视频",
    CompetitionText70:"作品以视频文件的形式提交，可结合视听语言剪辑成片，可适当精简情景故事，侧重展现产品的造型魅力，能够突出产品的特性，根据产品的风格调性作为影片基调方向，能够增强品牌形象的认同感，具有一定的思想深度。",
    CompetitionText71:"3.分镜脚本",
    CompetitionText72:"手绘或者电脑绘制以及视频素材拼接或者思维导图，主要说明影片故事梗概，理念说明、对造型和品牌的理解等。",
    CompetitionText73:"（日期+作者名称+院校+个人介绍）如：20230101_张XX_鲁美_个人介绍",
    CompetitionText74:"（日期+作者名称+院校+概念视频）如：20230101_张XX_鲁美_概念视频",
    CompetitionText75:"3.渲染图:",
    CompetitionText76:"（日期+作者名称+院校+渲染图①）如：20230101_张XX_鲁美_概念视频",
    CompetitionText77:"1.请参赛选手注册登陆大赛官方网站：<span style='font-weight:700'>saicdesign.com</span>",
    CompetitionText78:"2.根据提示填写个人信息。",
    CompetitionText79:"3.上传近照一份（JPG格式、分辨率不低于300dpi）",
    CompetitionText80:"4.上传参赛作品。",
    CompetitionText81:"注意",
    CompetitionText82:"上汽设计国际挑战赛组委会保留对本届赛事规则的最终解释权。",
    CompetitionText83:"大赛官方邮箱:saicdesign@saicmotor.com",
    CompetitionText84:"点击浏览任务书，获取更多大赛详情",
    Tagline:"大赛这十年“不一般”！</br> 我们有过天马行空的畅想，也收获过新颖的设计理念。</br> 当下外部环境的变化，我们呼吁设计回归新理性，</br> 为真实的世界寻找事物发展的内在规律：</br> 设计要解决什么问题？什么是正确的设计？如何做正确的设计？</br> 重新出发，让我们为美好生活寻求新的理解、创造新的感动！",
    CompetitionText85:"个人介绍:",
    CompetitionText86:"概念视频:",
    CompetitionText87:"UI设计效果图:",
    CompetitionText88:"说明文档:",
    CompetitionText89:"额外展示作品:",
    CompetitionText90:"设计方案报告：",
    CompetitionText91:"设计展示视频：",
    CompetitionText92:"渲染图：",
    CompetitionText93:"请参赛选手注册登陆大赛官方网站：<span style='font-weight:700'>saicdesign.com</span>",
    CompetitionText94:"根据提示填写个人信息。",
    CompetitionText95:"上传身份证图片、近照各一份（JPG格式、分辨率不低于300dpi）。",
    CompetitionText96:"上传参赛作品。",
    CompetitionText97:"个人介绍:",
    CompetitionText98:"研究报告:",
    CompetitionText99:"        移动性造就了城市。繁忙的港口，密布的公路，带来人口与资源的聚集，也带来城市的兴起与繁荣。城市的地理环境、文化审美，也影响了当地交通工具的形态和人们与车交互的方式。城市与车互相重塑，共同进化。请围绕用户体验，基于对真实城市生活的理解与洞察，思考汽车品牌如何融入城市文化？在未来，汽车文化与城市文化如何共同发展？车如何融于城，又如何为用户带来创新的体验？",
    CompetitionText100:"作品要求：",
    CompetitionText101:"本赛道倡导以系统设计思维驱动品牌体验创新。以《城市与车》为主题，针对品牌形象设计、产品与服务创新设计、可持续设计、新消费体验设计、适老化用车服务设计、社会创新设计等领域，以创造商业可持续、品牌可持续、创新可持续为指导，为用户带来有温度，有辨识度的品牌体验。",
    CompetitionText102:"提交材料：",
    CompetitionText103:"1、个人介绍",
    CompetitionText104:"（100秒自我介绍MP4视频*1，简历pdf*1）",
    CompetitionText105:"2、设计内容表述",
    CompetitionText106:"3、问题过程分析",
    CompetitionText107:"4、调研过程分析",
    CompetitionText108:"5、品牌定位分析",
    CompetitionText109:"6、创意设计呈现",
    CompetitionText110:"材料形式：",
    CompetitionText111:"除个人介绍材料外，其余材料建议以16:9 PDF文件、JPEG文件、MP4文件提交（要求图片像素不小于300M，视频材料时长3分钟以内)",
    CompetitionText112:"        当下，人工智能(AI)的快速发展给传统三维数字行业带来了新的挑战，但同时，AI对创作者们来说也是一把有利的创作工具。在数字可视化领域，我们鼓励以创新思维为核心，重新审视往届比赛中获奖作品的车型展示。通过风格化创意情景视觉呈现，突出汽车造型美学设计的魅力，彰显超级产品力。\n" +
      "        本次大赛以提供的数字模型（含往届赛事获奖作品的车型数字模型）为产品原型，鼓励选手使用各类创作工具（传统三维软件或AI工具），对静态和动态画面进行可视化设计，可进阶微调模型或颠覆原作品的场景化定义，让产品展示出丰富多样化感知与体验，对当下真实生活场景或未来生活方式进行积极思考和探索。",
    CompetitionText113:"作品要求:",
    CompetitionText114:"创意主题不限，作品内容思想健康、正能量、积极向上且有较好的精神面貌，无色情、暴力、血腥、政治等负面内容。素材元素需注明来源（遵循原创和无版权纠纷的内容为原则）。",
    CompetitionText115:"作品投稿材料：",
    CompetitionText116:"1、个人简介信息A4纸一页。",
    CompetitionText117:"2、创作意图100-200字（可选），",
    CompetitionText118:"主要说明内容梗概，创意理念说明，对造型设计和品牌的理解等。",
    CompetitionText119:"3、分镜设计",
    CompetitionText120:"（手绘/电脑绘制/素材拼接/PPT/思维导图）。",
    CompetitionText121:"4、简版动画样稿",
    CompetitionText122:"（非必选）。",
    CompetitionText123:"决赛评审材料：",
    CompetitionText124:"1、 个人介绍：",
    CompetitionText125:"100秒以内自我介绍MP4视频*1，简历pdf*1。",
    CompetitionText126:"2、 车型静态渲染图：",
    CompetitionText127:"数量：9张以上，尺寸：3840*2160，比例16：9，格式png或者jpeg。",
    CompetitionText128:"3、 最终动画视频：",
    CompetitionText129:"总决赛参赛作品以视频文件的形式提交，可结合视听语言剪辑成片，可适当精简情景故事，侧重展现产品的造型魅力，能够突出产品的特性，根据产品的风格调性作为影片基调方向，能够增强品牌形象的认同感，作品中有鲜明的创意表达和设计主张。",
    CompetitionText130:"视频格式：",
    CompetitionText131:"mp4格式（建议H264编码）1080p，视频尺寸比例 16：9，提交视频体积不大于800MB，时长不超过5分钟。",
    CompetitionText132:"提交规范：",
    CompetitionText133:"个人简介：",
    CompetitionText134:"日期+作者名称+院校+个人介绍",
    CompetitionText135:"创作意图：",
    CompetitionText136:"日期+作者名称+院校+创作意图",
    CompetitionText137:"分镜设计：",
    CompetitionText138:"日期+作者名称+院校+分镜设计",
    CompetitionText139:"简版动画样稿：",
    CompetitionText140:"日期+作者名称+院校+简版动画样稿",
    CompetitionText141:"        曾有数码博主称，中国汽车市场的特点是“车坐不开”，中国消费者的归宿一定是SUV和方盒子MPV；也有人坚信，激发人类本能的汽车运动文化将延续下去。同质化的“冰箱彩电沙发”和感性的速度之美如何平衡？品牌基因与新技术、新需求是什么关系？100岁的MG品牌正在展望下一个时代方向。\n" +
      "        请结合自己生活中的真实案例，如长辈们的真实用车需求、同龄年轻人对车的真实态度等，谈一谈你对未来5年中国乃至全球汽车生活形态的想法，并为MG品牌提出合适的发展策略，包括但不限于：",
    CompetitionText142:"1、品牌策略：",
    CompetitionText143:"未来的MG品牌能为用户提供什么价值？......",
    CompetitionText144:"2、产品策略：",
    CompetitionText145:"MG全球化产品型谱、产品核心亮点......",
    CompetitionText146:"3、造型策略：",
    CompetitionText147:"未来MG设计的风格方向、智能座舱体验策略......",
    CompetitionText148:"作品要求：",
    CompetitionText149:"观点明确、资料全面、数据真实可靠，有实地调研成果；框架逻辑清晰、画面逻辑清晰，具有易读性和吸引力。鼓励适当应用AI工具可视化策略成果。",
    CompetitionText150:"提交材料：",
    CompetitionText151:"1、个人介绍：",
    CompetitionText152:"100秒自我介绍MP4视频*1，简历pdf*1。",
    CompetitionText153:"2、研究报告：",
    CompetitionText154:"PDF格式，16:9横向排版。",
    CompetitionText155:"       始于屏幕，忠于体验。交互设计从用户需求出发，注重可用性与用户体验。\n" +
      "       比起想象里超凡的智能驾驶空间，我们更加关注用户“下一次”的智能驾乘体验。TA是一束温暖友好的灯光，一种熟悉的味道，一瞬对电量的焦虑…\n" +
      "       感性与理性结合，想象与科技交融，理想与现实交织。本次赛事延续真实世界的主题，延展出可预见的未来想象。请选手基于对新能源汽车及行业的理解，从「功能性」出发，以HMI为介入点，打造「有体验」的智能座舱。",
    CompetitionText156:"作品要求",
    CompetitionText157:"请选手阅读分赛道HMI任务书文件，根据BRIEF完成HMI方案设计。",
    CompetitionText158:"提交材料：",
    CompetitionText159:"1、个人介绍：",
    CompetitionText160:"100秒自我介绍MP4视频*1，简历pdf*1。",
    CompetitionText161:"2、UI设计效果图：",
    CompetitionText162:"jpeg格式，色彩RGB，分辨率不低于300dpi，仪表1920*720，中控1920*1080，至少3张。",
    CompetitionText163:"3、设计概念解析：",
    CompetitionText164:"PDF格式，色彩RGB，单页16:9纵向排版。包含交互概念，视觉概念，设计亮点解读。明确用车场景，展示洞察力与有效可行的解决思路。尺寸需要手机阅读舒适，比例适宜，字体清晰可读。",
    CompetitionText165:"4、概念视频：",
    CompetitionText166:"不超过3分钟，不超过500m。",
    CompetitionText167:"5、额外展示作品：",
    CompetitionText168:"任何参赛选手想要额外上传展示的附加内容。个人网站/作品模型/可操作原型/appendix/动效…格式不限，但建议能友好查阅。",
    CompetitionText169:"        在汽车品牌激烈竞争的当下，巧妙利用CMF设计能给品牌打造独属印记，加深人们对品牌的印象。\n" +
      "        面对真实世界的CMF设计不能只局限于整车内外饰，同时也要考虑产品营销的触点。如具体的品牌颜色、车型沟通色、品牌线下门店设计等等，这些都能成为CMF设计的对象。",
    CompetitionText170:"作品要求",
    CompetitionText171:"请结合所给的品牌方向及素材，从CMF设计角度思考，形成品牌下一代的CMF设计方案。",
    CompetitionText172:"提交材料：",
    CompetitionText173:"1、个人介绍：",
    CompetitionText174:"个人简历pdf*1，100秒自我介绍MP4视频*1。",
    CompetitionText175:"2、方案报告：",
    CompetitionText176:"1份完整16:9报告，横向排版。 包含主题阐释/目标人群分析/设计趋势/故事板/材质板/内外饰效果图。",
    CompetitionText177:"3、创新材料：",
    CompetitionText178:"结合环保理念，在作品中给出切实创新的环保材料设计说明。",
    CompetitionText179:"4、展示视频：",
    CompetitionText180:"要求提供展示CMF设计方案的视频，时长不超过2min，MP4格式，1920*1080，小于500M。",
    CompetitionText181:"1、请参赛选手注册登陆大赛官方网站：",
    CompetitionText182:"saicdesign.com",
    CompetitionText183:"2、根据提示填写个人信息。",
    CompetitionText184:"3、上传近照一份",
    CompetitionText185:"（JPG格式、分辨率不低于300dpi）",
    CompetitionText186:"4、上传参赛作品。",
    CompetitionText187:"注意：",
    CompetitionText188:"上汽设计国际挑战赛组委会保留对本届赛事规则的最终解释权。",

  // 大赛页面 end

    // 上传页面
    Title: '上汽设计国际挑战赛报名',
    BasicInfo: '基本信息',
    BasicDesc: '请如实填写以下信息，我们向您承诺，此信息只用于上汽设计国际挑战赛',
    Division: '赛区',
    Required: '(必填)',
    LastName: '姓',
    Name: '名',
    Gender: '性别',
    Male: '男',
    Female: '女',
    Phone: '手机号',
    Email: '邮箱',
    EmailInfo: '注: 一个邮箱和一个身份证号视为一个报名账号',
    Birth: '出生日期',
    IDnumber: '身份证号',
    Education: '学历',
    School: '学校',
    Major: '专业',
    Graduate: '毕业时间',
    Upload: '上传作品',
    Namework: '作品名称',
    Description: '说明',
    UploadPhoto: '上传近照',
    PictureDesc: '支持JPG格式，文件最大为30MB，分辨率不低于300dpi',
    UploadDesc: '支持上传jpg、pdf、视频，单个文件不超过30M，单个视频不超过500M；',
    DragDesc: '支持上传jpg、png、pdf、mov\n' + '拖拽图片排序',
    Delete: '删除',
    ResumeWork: '简历和其他作品',
    NotRequired: '(非必填)',
    Resume: '简历',
    SampleReels: '作品集',
    ResumeInfo: '支持word、pdf、zip， 不超过30M',
    WorksInfo: '支持上传pdf、视频、zip， PDF不超过30M，视频不超过500M；',
    read: '我已阅读并同意',
    And: '和',
    UserRule: '《用户协议》',
    Privacy: '《隐私政策》',
    Submit: '提交',
    Select: '请选择',
    Input: '请输入',
    SubmitValid: '检测到已有作品，请问是否覆盖原有作品信息？',
    China: '中国',
    Europe: '欧洲',
    Japan: '日本',
    Styling: '主赛道',
    Design: '品牌战略与服务设计',
    HMI: 'HMI设计',
    CMF: 'CMF设计',
    Digital: '数字可视化设计',
    Strategy: '品牌研究与设计策略',
    Undergraduate: '本科',
    UndergraduateIn: '本科在读',
    Graduates: '硕士',
    GraduateIn: '硕士在读',
    beforeUpload: '注: 需填写赛区、邮箱、身份证号后才可上传',
    emialValid: '邮箱格式错误',
    idCardValid: '身份证号格式错误',
    Error: '错误',
    timeout: '当前访问人数多，请尝试刷新页面或稍后重试',
    videoSize: '视频文件不能大于500MB',
    fileSize: '当前类型文件大小不能超过30MB',
    uploadValid: '请上传您的作品',
    PictureValid: '请上传您的近照',
    checkWork: '检测到已有作品，请问是否覆盖原有作品信息',
    submitSuccess: '提交成功',
    rule: '请先勾选《用户协议》和《隐私政策》',
    checkValid: '请检查必填项是否填写',
    Downloading:"下载中",
    zoneValid: '请选择赛区',
    emailValid: '请输入邮箱',
    emialFormat: '邮箱格式错误',
    idNumberValid: '请输入身份证号',
    idNumberFormat: '身份证号格式错误',
    uploadError: '上传失败',
    uploadTokenError: '请稍后再尝试上传'
}
