<template>
  <div class="containner">
    <div v-if="submit" class="loading-uploader">
      <img src="@/assets/loading.png" />
    </div>
    <header-pc class="upload-header" src="@/assets/logo.png" :fixed="data.fixed" :language="locale"></header-pc>
    <div class="upload-content">
      <div class="logo-title">
        <img src="@/assets/logo.png" alt />
        <h2>{{ $t("Title") }}</h2>
      </div>
      <a-form
        ref="form"
        :model="data.formState"
        :rules="rules"
        layout="vertical"
        :labelWrap="true"
        :hideRequiredMark="true"
      >
        <div class="basic-form">
          <p>
            <span>{{ $t("BasicInfo") }}</span>
            <span>{{ $t("BasicDesc") }}</span>
          </p>
        </div>
        <a-row justify="space-between">
          <a-col :span="12" style="padding-right: 20px;">
            <a-form-item name="zone">
              <template v-slot:label>
                <span>{{ $t("Division") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-select v-model:value="data.formState.zone" :placeholder="$t('Select')">
                <a-select-option value="中国">{{ $t('China') }}</a-select-option>
                <a-select-option value="欧洲">{{ $t('Europe') }}</a-select-option>
                <a-select-option value="日本">{{ $t('Japan') }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-row type="flex" justify="space-between">
              <a-col :span="8">
                <a-form-item name="surname">
                  <template v-slot:label>
                    <span>{{ $t("LastName") }}</span>
                    <span>{{ $t("Required") }}</span>
                  </template>
                  <a-input v-model:value="data.formState.surname" :placeholder="$t('Input')"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="15">
                <a-form-item name="name">
                  <template v-slot:label>
                    <span>{{ $t("Name") }}</span>
                    <span>{{ $t("Required") }}</span>
                  </template>
                  <a-input v-model:value="data.formState.name" :placeholder="$t('Input')"></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-form-item>
              <template v-slot:label>
                <span>{{ $t("Phone") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <div class="phone-wrapper">
                <a-form-item name="areaCode" style="flex: 2; margin-bottom: 0">
                  <a-select
                    v-model:value="data.formState.areaCode"
                    show-search
                    :dropdownMatchSelectWidth="false"
                    :placeholder="$t('Select')"
                    @search="filterOption"
                    @select="handleSelect"
                  >
                    <a-select-option
                      v-for="item in data.options"
                      :value="locale === 'zh' ? item.countryCn : item.countryEn"
                    >
                      <span v-if="locale === 'zh'">{{item.countryCn}}</span>
                      <span v-else>{{item.countryEn}}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item name="phone" style="flex: 8; margin-bottom: 0">
                  <a-input
                    v-model:value="data.formState.phone"
                    type="number"
                    :placeholder="$t('Input')"
                  ></a-input>
                </a-form-item>
              </div>
            </a-form-item>
            <a-form-item name="birthday">
              <template v-slot:label>
                <span>{{ $t("Birth") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-date-picker
                v-model:value="data.formState.birthday"
                format="YYYY-MM-DD"
                :placeholder="$t('Select')"
              />
            </a-form-item>
            <a-form-item name="qualification">
              <template v-slot:label>
                <span>{{ $t("Education") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-select v-model:value="data.formState.qualification" :placeholder="$t('Select')">
                <a-select-option value="1">
                  {{
                  $t("Undergraduate")
                  }}
                </a-select-option>
                <a-select-option value="2">
                  {{
                  $t("UndergraduateIn")
                  }}
                </a-select-option>
                <a-select-option value="3">
                  {{
                  $t("Graduates")
                  }}
                </a-select-option>
                <a-select-option value="4">
                  {{
                  $t("GraduateIn")
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="data.formState.zone && data.formState.zone == '中国'" name="major">
              <template v-slot:label>
                <span>{{ $t("Major") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-input v-model:value="data.formState.major" :placeholder="$t('Input')"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12" style="padding-left: 20px;">
            <a-form-item name="track" v-if="data.formState.zone == '中国'">
              <template v-slot:label>
                <span>{{ $t("RaceTrack") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-select v-model:value="data.formState.track" :placeholder="$t('Select')">
                <a-select-option value="1">{{ $t('Styling') }}</a-select-option>
                <a-select-option value="6">{{ $t('Design') }}</a-select-option>
                <a-select-option value="5">{{ $t('Strategy') }}</a-select-option>
                <a-select-option value="3">{{ $t('CMF') }}</a-select-option>
                <a-select-option value="4">{{ $t('Digital') }}</a-select-option>
                <a-select-option value="2">{{ $t('HMI') }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="sex">
              <template v-slot:label>
                <span>{{ $t("Gender") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-radio-group v-model:value="data.formState.sex">
                <a-radio-button value="0">{{ $t("Male") }}</a-radio-button>
                <a-radio-button value="1">{{ $t("Female") }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item name="email">
              <template v-slot:label>
                <span>{{ $t("Email") }}</span>
                <span style="font-size: 12px; opacity: 0.4">
                  {{
                  $t("Required")
                  }}
                </span>
                <span
                  style="
                    font-size: 12px;
                    font-weight: bold;
                    padding-left: 8px;
                    color: #ff4d4f;
                    opacity: 1;
                  "
                >{{ $t("EmailInfo") }}</span>
              </template>
              <a-input v-model:value="data.formState.email" :placeholder="$t('Input')"></a-input>
            </a-form-item>
            <a-form-item name="idCard" v-if="data.formState.zone == '中国'">
              <template v-slot:label>
                <span>{{ $t('IDnumber') }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-input v-model:value="data.formState.idCard" :placeholder="$t('Input')"></a-input>
            </a-form-item>
            <a-form-item name="school">
              <template v-slot:label>
                <span>{{ $t("School") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-input v-model:value="data.formState.school" :placeholder="$t('Input')"></a-input>
            </a-form-item>
            <a-form-item name="graduationTime">
              <template v-slot:label>
                <span>{{ $t("Graduate") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-date-picker
                v-model:value="data.formState.graduationTime"
                format="YYYY-MM-DD"
                :placeholder="$t('Select')"
              />
            </a-form-item>
            <a-form-item v-if="data.formState.zone != '中国'" name="major">
              <template v-slot:label>
                <span>{{ $t('Major') }}</span>
                <span>{{ $t('Required') }}</span>
              </template>
              <a-input v-model:value="data.formState.major" :placeholder="$t('Input')"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="work-form">
          <p>
            <span>{{ $t('Upload') }}</span>
            <span
              style="font-size: 12px; font-weight: bold; padding-left: 8px; color: #ff4d4f; opacity: 1;"
            >{{ $t('beforeUpload') }}</span>
          </p>
        </div>
        <a-row>
          <a-col :span="11">
            <a-form-item name="workName">
              <template v-slot:label>
                <span>{{ $t("Namework") }}</span>
                <span>{{ $t("Required") }}</span>
              </template>
              <a-input
                v-model:value="data.formState.workName"
                maxlength="20"
                :placeholder="$t('Input')"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item name="workExplain">
              <template v-slot:label>
                <span>{{ $t("Description") }}</span>
                <span></span>
              </template>
              <a-textarea
                auto-size
                show-count
                v-model:value="data.formState.workExplain"
                maxlength="2000"
                :placeholder="$t('Input')"
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <template v-slot:label>
                <span>{{ $t('Upload') }}</span>
                <span style="font-size: 12px; opacity: .4; padding-left: 8px;">{{ $t('Required') }}</span>
                <span
                  style="font-size: 12px; opacity: .4; padding-left: 8px;"
                >{{ $t('UploadDesc') }}</span>
              </template>
              <upload
                ref="work"
                :oss="data.oss"
                name="作品"
                :limit="10000"
                :multiple="true"
                :arguments="data.formState"
                accept=".jpg, .png, .pdf, .mov, .mp4"
                :tips="$t('DragDesc')"
                @change="changeFlag"
              ></upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <template v-slot:label>
                <span>{{ $t('UploadPhoto') }}</span>
                <span style="font-size: 12px; opacity: .4; padding-left: 8px;">{{ $t('Required') }}</span>
              </template>
              <upload
                ref="picture"
                :oss="data.oss"
                name="近照"
                :limit="1"
                :multiple="false"
                :arguments="data.formState"
                accept=".jpg, .png"
                :tips="$t('PictureDesc')"
                @change="changeFlag"
              ></upload>
            </a-form-item>
          </a-col>
        </a-row>
        <p>
          {{ $t("ResumeWork") }}
          <span style="opacity: 0.4">{{ $t("NotRequired") }}</span>
        </p>
        <a-row>
          <a-col :span="6">
            <a-form-item>
              <p style="padding: 8px 0">{{ $t("Resume") }}</p>
              <upload
                ref="resume"
                :oss="data.oss"
                name="简历"
                :limit="1"
                :multiple="false"
                :arguments="data.formState"
                accept=".doc, .docx, .pdf, .zip"
                :tips="$t('ResumeInfo')"
                @change="changeFlag"
              ></upload>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <p style="padding: 8px 0;">{{ $t('SampleReels') }}</p>
              <upload
                ref="works"
                :oss="data.oss"
                name="作品集"
                :limit="1"
                :multiple="false"
                :arguments="data.formState"
                accept=".pdf, .mp4, .mov, .zip"
                :tips="$t('WorksInfo')"
                @change="changeFlag"
              ></upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-checkbox v-model:checked="data.formState.remember">
        {{ $t("read") }}
        <span @click.prevent="handleReadUserRule">{{ $t("UserRule") }}</span>
        {{ $t("And")
        }}
        <span
          @click.prevent="handleReaconcealRule"
        >{{ $t("Privacy") }}</span>
      </a-checkbox>
    </div>
    <button class="submit-btn button" @click="handleSubmit">{{ $t('Submit') }}</button>
    <a-drawer
      height="90%"
      :title="data.title"
      placement="bottom"
      :visible="visible"
      @close="onClose"
    >
      <div v-if="drawer" class="loading-drawer">
        <img src="@/assets/loading.png" />
      </div>
      <template v-else>
        <vue-pdf-embed :source="pdfSrc"></vue-pdf-embed>
      </template>
    </a-drawer>
  </div>
</template>

<script setup>
import axios from "axios";
import moment from "moment";
import API from "@/api/index.js";
import { useRouter } from "vue-router";
import vuePdfEmbed from "vue-pdf-embed"
import { renderAsync } from 'docx-preview';
import { Modal, message } from 'ant-design-vue';
import upload from '@/components/upload.vue';
import headerPc from "@/components/header-pc.vue";
import footerLayout from "@/components/footer.vue";
import {ref, reactive, onMounted, onBeforeUnmount, nextTick, inject, watch} from "vue";
import { useI18n } from 'vue-i18n';
const options = require('../assets/videoJson/area.json').options

const { t, locale } = useI18n();
const router = useRouter();
const visible = ref(false)
const form = ref()
const pdfSrc = ref()
const work = ref(null)
const picture = ref(null)
const resume = ref(null)
const works = ref(null)
const submit = ref(false)
const drawer = ref(false)
const rules = ref(null)
const flag = ref(false)
const request = ref(false)
const previewVisible = ref(false)
const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));
const indicator = inject('indicator')

watch(() => locale.value, (val) => {
  rules.value = {
    zone: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    track: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    surname: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    name: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    sex: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    areaCode: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    phone: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    email: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
      {
        pattern: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
        required: true,
        message: t("emialValid"),
        trigger: "change",
      },
    ],
    birthday: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    idCard: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
      {
        pattern:
            /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
        required: true,
        message: t("idCardValid"),
        trigger: "change",
      },
    ],
    qualification: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    school: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    major: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    graduationTime: [
      {
        required: true,
        message: t("Select"),
        trigger: "change",
      },
    ],
    workName: [
      {
        required: true,
        message: t("Input"),
        trigger: "change",
      },
    ],
    workExplain: [
      {
        required: false,
        message: t("Input"),
        trigger: "change",
      },
    ],
  }
  nextTick(() => {
    form.value.clearValidate()
  })
}, { immediate: true })

const data = reactive({
  id: null,
  oss: null,
  title: null,
  workId: null,
  commonSrc: null,
  areaData: [],
  fileList: [],
  works: [],
  resume: [],
  options: [],
  formState: {
    zone: null,
    track: null,
    surname: null,
    name: null,
    sex: null,
    areaCode: '+86',
    phone: null,
    email: null,
    birthday: null,
    idCard: null,
    qualification: null,
    school: null,
    major: null,
    graduationTime: null,
    workName: null,
    workExplain: null,
    ipAddress: null,
    timestamp: null,
  },
});

onMounted(() => {
  data.options = []
  data.areaData = []
  data.areaData = JSON.parse(JSON.stringify(options))
  for (let i = 0 ; i < data.areaData.length; i++) {
    data.areaData[i].countryCn += `(+${data.areaData[i].value})`
    data.areaData[i].countryEn += `(+${data.areaData[i].value})`
  }
  data.options = data.areaData.slice(0, 20)
  window.scrollTo(0, 0);
  fetchGetSts()
  fetchGetIp()
});

const onClose = (state) => {
  visible.value = false;
};

const changeFlag = (state) => {
  flag.value = state
  fetchGetSts()
}

const handleCancel = (state) => {
  previewVisible.value = false;
};

// 区号数据处理
const handleSelect = (value) => {
  data.formState.areaCode = `+${value.split('+')[1]}`
  let len = data.formState.areaCode.length
  data.formState.areaCode = data.formState.areaCode.slice(0, len - 1)
}

// 区号筛选
const filterOption = (value) => {
  if (!value) {
    data.options = data.areaData.slice(0, 20)
    return
  }
  data.options = data.areaData.filter(item => {
    return item.countryCn.includes(value) || item.countryEn.includes(value)
  })
};

// 判断是否重复上传
const detail = () => {
  let language = null;
  locale.value === "zh" && (language = "CN");
  locale.value === "en" && (language = "EN");
  let formData = {
    zone: data.formState.zone,
    email: data.formState.email,
    idCard: data.formState.idCard,
    language: language,
  };
  if (formData.zone != '中国' && data.formState.email) {
    delete formData.idCard;
  }
  API.detail(formData).then((res) => {
    if (!res.data) {
      fetchHandleSubmit();
      return
    }
    data.id = res.data.id
    data.workId = res.data.workVo.id
    Modal.confirm({
      title: () => '警告',
      okText: '确认',
      cancelText: '取消',
      content: () => t("checkWork"),
      onOk: () => {
        fetchHandleSubmit();
      },
      onCancel: () => {},
    });
  });
};

// 提交校验
const handleSubmit = () => {
  console.log(data.formState)
  form.value
    .validate()
    .then((res) => {
      if (work.value.fileList && !work.value.fileList.length) {
        message.error(t("uploadValid"));
        return;
      }
      if (picture.value.fileList && !picture.value.fileList.length) {
        message.error(t("PictureValid"));
        return;
      }
      if (!data.formState.remember) {
        message.error(t("rule"));
      } else {
        detail()
      }
    })
    .catch((error) => {
      message.error(t("checkValid"));
    });
};

// 提交作品
const fetchHandleSubmit = () => {
  let language = null;
  let formData = JSON.parse(JSON.stringify(data.formState));
  let designFileVoList = [];
  let start = moment(formData.birthday).format("YYYY-MM-DD");
  let end = moment(formData.graduationTime).format("YYYY-MM-DD");
  data.id && (formData.id = data.id);
  locale.value === "zh" && (language = "CN");
  locale.value === "en" && (language = "EN");
  formData.language = language;
  formData.workVo = {
    workName: data.formState.workName,
    workExplain: data.formState.workExplain,
  };
  data.workId && (formData.workVo.id = data.workId)
  formData.designFileVoList = designFileVoList.concat(
    work.value.fileList,
    picture.value.fileList,
    resume.value.fileList,
    works.value.fileList
  );
  formData.birthday = start;
  formData.graduationTime = end;
  !data.formState.track && (formData.track = 1)
  formData.designFileVoList.forEach((item, index) => {
    item.src && (item.src = '')
    item.serialNo = index;
    let arr = item.fileUrl.split('/');
    arr[4] = formData.email;
    formData.zone == '中国' && (arr[5] = formData.idCard);
    item.fileUrl = arr.join('/')
  });
  submit.value = true;
  API.submit(formData)
    .then((res) => {
      if (res.state != 200) {
        message.error(res.msg);
        submit.value = false;
        return;
      }
      data.id = null;
      data.workId = null;
      submit.value = false;
      data.formState.remember = false;
      message.success(t("submitSuccess"));
      form.value.resetFields();
      router.push('/eventDetail')
    }).catch((err) => {
      submit.value = false;
    });
};

const fetchGetSts = () => {
  if (flag.value) return
  if (request.value) return
  request.value = true
  API.ossInfo().then(res => {
    data.oss = res.data
    flag.value = true
    request.value = false
  }).catch(() => {
    flag.value = false
    request.value = false
  })
}

// 协议在线预览
const handleReadUserRule = () => {
  let path = null
  drawer.value = true
  visible.value = true
  locale.value === 'zh' && (path = 'SAIC_Design/prod/document/用户协议.pdf')
  locale.value === 'en' && (path = 'SAIC_Design/prod/document/用户协议_翻译.pdf')
  API.download({
    ossFilePath: path
  }).then(res => {
    let blob = new Blob([res], { type: 'application/pdf' })
    pdfSrc.value = window.URL.createObjectURL(blob)
    drawer.value = false
  })
}
// 隐私政策在线预览
const handleReaconcealRule = () => {
  let path = null
  drawer.value = true
  visible.value = true
  locale.value === 'zh' && (path = 'SAIC_Design/prod/document/隐私政策.pdf')
  locale.value === 'en' && (path = 'SAIC_Design/prod/document/隐私政策_英语.pdf')
  API.download({
    ossFilePath: path
  }).then(res => {
    let blob = new Blob([res], { type: 'application/pdf' })
    pdfSrc.value = window.URL.createObjectURL(blob)
    drawer.value = false
  })
}

// 获取IP地址
const fetchGetIp = () => {
  API.getIpAdress().then(res => {
    data.formState.ipAddress = res.data;
    data.formState.timestamp = +new Date();
  })
}
</script>

<style lang="less">
.loading-drawer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-drawer img {
  width: 320px;
  height: auto;
  opacity: 0.2;
  animation: loading 1.2s ease-in infinite forwards;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body {
  display: flex;
  justify-content: center;
  align-items: center;
  .vue-pdf-embed,
  #docx_detail,
  video {
    width: 100%;
    height: 100%;
  }
}
.ant-modal-confirm-btns .ant-btn:first-child:hover {
  color: #ED89B8;
  border-color: #ED89B8;
}
</style>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
}

.loading-uploader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-uploader img {
  width: 320px;
  height: auto;
  opacity: 0.2;
  animation: loading 1.2s ease-in infinite forwards;
}
.upload-header {
  background: #ffffff;
  backdrop-filter: blur(0);
  box-shadow: 0 0 4px 0 #000000;
}

.submit-btn {
  margin-left: 50%;
  margin-bottom: 80px;
  cursor: pointer;
  font-size: 16px;
  transform: translateX(-50%);
}

.upload-content {
  width: 960px;
  margin: 0 auto;
  margin-bottom: 80px;
  .logo-title {
    display: flex;
    flex-direction: column;
    ustify-content: center;
    align-items: center;
    padding: 88px 80px 0;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 16px;
    }
    h2 {
      font-size: 24px;
      line-height: 33px;
    }
  }
  .basic-form,
  .work-form {
    height: 40px;
    margin-top: 42px;
    margin-bottom: 24px;
    border-bottom: 1px solid #000000;
    span:first-child {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }
    span:last-child {
      font-size: 12px;
      line-height: 17px;
      padding-left: 8px;
      opacity: 0.4;
    }
  }
  .ant-form > p {
    height: 40px;
    margin-top: 48px;
    line-height: 40px;
    border-bottom: 1px solid;
  }
  .ant-form > p span:first-child {
    font-size: 16px;
  }
  .ant-form > p span:last-child {
    font-size: 12px;
    padding-left: 8px;
  }
  .ant-form :deep(.ant-input) {
    height: 36px;
    padding: 8px 16px;
    border-radius: 8px;
  }
  .ant-form :deep(.ant-picker) {
    width: 100%;
    height: 100%;
  }
  .ant-form :deep(.phone-wrapper) {
    display: flex;
    flex-direction: row;
  }
  .ant-form :deep(.ant-form-item-has-error .ant-input) {
    border-color: #ff0000 !important;
  }
  .ant-form :deep(.ant-form-item-has-error .ant-picker) {
    border-color: #ff0000 !important;
  }
  .ant-form :deep(.ant-form-item-has-error .ant-radio-button-wrapper) {
    border-color: #ffffff !important;
  }
  .ant-form :deep(.phone-wrapper .ant-form-item .ant-select-selector) {
    border-radius: 8px 0 0 8px !important;
  }
  .ant-form :deep(.phone-wrapper .ant-form-item .ant-input) {
    border-radius: 0 8px 8px 0 !important;
  }
  .ant-form :deep(.ant-form-item-label label span:first-child) {
    font-size: 14px;
  }
  .ant-form :deep(.ant-form-item-label label span:last-child) {
    opacity: 0.4;
    font-size: 12px;
    padding-left: 4px;
  }
  & :deep(.ant-form .ant-form-item .upload-wrapper) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .loaidng-upload {
      width: 208px;
      height: 117px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #dfdfdf;
    }
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .loaidng-upload img {
      width: 80%;
      height: auto;
      opacity: 0.2;
      animation: loading 1.2s ease-in infinite forwards;
    }
    .avatar-uploader {
      width: 208px;
      height: 117px;
    }
    .file-item {
      width: 208px;
      height: 117px;
      padding: 16px;
      overflow: hidden;
      background: #d9d9d9;
      border-radius: 4px;
      margin-right: 16px;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: move;
    }
    .file-item .file-wrapper {
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .file-item img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 4px;
    }
    .file-item span.file-title {
      width: 100%;
      height: 30%;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .file-item span.anticon {
      font-size: 32px;
      padding-bottom: 12px;
    }
    .file-item:hover .delete {
      display: block;
    }
    .file-item .delete {
      min-width: 208px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(#000, 0.2);
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      display: none;
    }
  }
  &
    :deep(
      .ant-form
        .ant-form-item
        .avatar-uploader
        .ant-upload-list
        .ant-upload-list-picture-card-container
    ) {
    width: 208px;
    height: 117px;
  }
  &
    :deep(
      .ant-form
        .ant-form-item
        .avatar-uploader
        .ant-upload.ant-upload-select-picture-card
    ) {
    width: 208px;
    height: 117px;
    border-radius: 4px;
    color: #000000;
    opacity: 0.4;
    background-color: #ebebeb;
    &:hover {
      border-color: #000000;
    }
    .ant-upload {
      width: 100%;
      height: 100%;
    }
    .ant-upload div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ant-upload div p {
      height: 36px;
    }
    .ant-upload div .ant-upload-text {
      font-size: 12px;
      margin-top: 16px;
    }
  }
  & :deep(> .ant-row .ant-col) {
    margin-top: 22px;
    margin-right: 22px;
  }
  & :deep(> .ant-row .ant-col p) {
    font-size: 14px;
    padding-bottom: 8px;
  }
  & :deep(> .ant-row .ant-col .ant-upload) {
    border: 0;
    width: 100%;
    padding: 0 16px;
    height: 145px;
    border-radius: 4px;
    opacity: 0.6;
    color: #000000;
    background: #ebebeb;
  }
  .ant-checkbox-wrapper span:nth-child(2) > span {
    color: #165dff;
    cursor: pointer;
  }
}
</style>
