<template>
  <footer class="layout-footer">
    <div class="footer-content">
      <div class="company">
        <a-spin :spinning="!props.src" :indicator="indicator">
          <img :src="props.src && props.src[1].extranetUrl" alt="" />
        </a-spin>
        <div class="copyright">
          <span>©2023上汽集团版权所有</span>
          <span @click="goOutWebsite">沪ICP备2023008312号-1</span>
          <div
            style="
              cursor: pointer;
              margin: 0 auto;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            @click="goElseWeb"
          >
            <img
              style="width: 20px !important; height: 20px !important"
              src="@/assets/police.png"
              alt=""
            />
            <p
              style="
                color: #ffffff;
                opacity: 0.4;
                margin-left: 2px;
                font-size: 12px;
              "
            >
              沪公网安备 31011402010092号
            </p>
          </div>
        </div>
      </div>
      <div class="QR-code-footer">
        <a-tooltip color="#fff" placement="top">
          <template v-if="props.src" #title>
            <a-spin :spinning="!props.src" :indicator="indicator">
              <img
                style="width: 116px; height: 116px"
                :src="props.src && props.src[2].extranetUrl"
              />
            </a-spin>
          </template>
          <div class="code-item">
            <a-spin :spinning="!props.src" :indicator="indicator">
              <img :src="props.src && props.src[2].extranetUrl" />
            </a-spin>
            <span>{{ $t("OfficialWeibo") }}</span>
          </div>
        </a-tooltip>
        <a-tooltip color="#fff" placement="top">
          <template v-if="props.src" #title>
            <a-spin :spinning="!props.src" :indicator="indicator">
              <img
                style="width: 116px; height: 116px"
                :src="props.src && props.src[3].extranetUrl"
              />
            </a-spin>
          </template>
          <div class="code-item" style="margin-right: 0">
            <a-spin :spinning="!props.src" :indicator="indicator">
              <img :src="props.src && props.src[3].extranetUrl" />
            </a-spin>
            <span>{{ $t("OfficialWeChat") }}</span>
          </div>
        </a-tooltip>
      </div>
      <div class="copyright">
        <span>©2023上汽集团版权所有</span>
        <span @click="goOutWebsite">沪ICP备2023008312号-1</span>
        <div
          style="margin: 0 auto; display: flex; align-items: center"
          @click="goElseWeb"
        >
          <img style="opacity: 1 !important" src="@/assets/police.png" alt="" />
          <span>沪公网安备 31011402010092号</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const indicator = inject("indicator");
const props = defineProps({
  src: {
    type: String,
    default: "",
  },
});
const goElseWeb = () => {
  window.open(
    "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402010092"
  );
};

const goOutWebsite = () => {
  window.open("https://beian.miit.gov.cn/");
};
</script>

<style lang="less">
.QR-code-footer {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  .code-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    overflow: hidden;
    text-align: center;
  }

  .code-item img {
    width: 64px !important;
    height: 64px !important;
    padding: 4px;
    margin-bottom: 4px;
  }

  .code-item span {
    font-size: 12px;
    line-height: 18px;
    opacity: 0.6;
    color: #ffffff;
  }
}
@media screen and (min-width: 751px) {
  .layout-footer {
    width: 100%;
    height: 150px;
    padding: 35px 0;

    background-color: #000000;
  }
  .layout-footer .footer-content {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .layout-footer .footer-content > .copyright {
    display: none;
    span:nth-child(n + 2) {
      cursor: pointer;
    }
  }
  .layout-footer .footer-content .company {
    overflow: hidden;
  }
  .layout-footer .company img {
    width: 282px !important;
    height: 40px !important;
  }
  .layout-footer .copyright {
    display: flex;
    flex-flow: row wrap;
    margin-top: 24px;
    align-items: center;
  }
  .layout-footer .copyright span {
    opacity: 0.4;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
  .layout-footer .copyright span:nth-child(n + 2) {
    cursor: pointer;
  }
  .layout-footer .copyright span:nth-child(2) {
    margin: 0 27px;
  }
}

@media screen and (max-width: 750px) {
  .layout-footer {
    width: 100%;
    padding: 32px 20px 24px;
    background-color: #000000;
  }
  .layout-footer .footer-content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .layout-footer .footer-content .QR-code {
    width: 50%;
    margin-top: 24px;
    display: flex;
    flex-display: row;
    justify-content: space-between;
  }
  .layout-footer .footer-content > copyright {
    display: block;
  }
  .layout-footer .copyright {
    display: flex;
    flex-flow: row wrap;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    padding-top: 26px;
  }

  .layout-footer .copyright span {
    opacity: 0.4;
  }

  .layout-footer .company {
    width: 282px;
    height: 40px;
    overflow: hidden;
  }
  .layout-footer .company img {
    width: 282px !important;
    height: 40px !important;
  }

  .layout-footer .copyright span {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
