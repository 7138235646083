import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import i18n from './locals'
import antd from 'ant-design-vue'
import 'animate.css'
import 'ant-design-vue/dist/antd.css'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import './utils/flexible'
import '@/assets/fonts/font.css';

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(antd)
app.use(NutUI)


app.mount('#app')
