<template>
  <div class="containner">
    <!--PC导航 start-->
    <header
      :class="{ fixed: data.fixed }"
      ref="headerPc"
      class="layout-header-PC"
    >
      <img :src="data.logo" @click="router.replace('/index')" />
      <!-- <ul class="nav-bar">
        <a href="#we-story">关于我们</a>
        <a href="#excellent-works-PC">{{ $t("login.userName") }}</a>
        <a href="#excellent-works-PC">团队</a>
        <a>作品</a>
        <a @click="onEvent">SDC大赛</a>
      </ul> -->
      <div class="language-wrapper">
        <button @click="handleJump">Enter Europe</button>
        <!-- <span :style="{ color: locale === 'zh' ? '#FB3995' : '#dfdfdf' }" @click="changeLang('zh')">CN</span> -->
        <!-- <span :style="{ color: locale === 'en' ? '#FB3995' : '#dfdfdf' }" @click="changeLang('en')">EN</span> -->
      </div>
      <!-- <a-tooltip
        placement="bottom"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <template #title>
          <div class="language-wrapper">
            <p
              style="padding: 4px 16px; cursor: pointer"
              :style="{ opacity: locale === 'zh' ? 1 : 0.6 }"
              @click="changeLang('zh')"
            >
              {{ $t("Chinese") }}
            </p>
            <p
              style="padding: 4px 16px; cursor: pointer"
              :style="{ opacity: locale === 'en' ? 1 : 0.6 }"
              @click="changeLang('en')"
            >
              {{ $t("English") }}
            </p>
          </div>
        </template>
        <span>
          {{ locale === "zh" ? $t("Chinese") : $t("English") }}
          <img src="@/assets/Frame.png" alt="" />
        </span>
      </a-tooltip> -->
    </header>
    <!-- PC导航 end -->
    <!-- Mobile导航 start -->
    <header-phone
      :src="data.commonSrc && data.commonSrc[0]['extranetUrl']"
      :isGoBack="true"
    >
      <template v-slot:nav>
        <a @click="router.replace('/')">{{ $t("HomePage") }}</a>
      </template></header-phone
    >
    <!-- Mobile导航 end -->
    <!--PC倒计时 start-->
    <article
      class="count-down"
      id="count-down"
      v-if="!data.isMobile"
      :style="{
        backgroundImage:
          'url(' +
          data.backgroundImage1 +
          '),' +
          'url(' +
          data.backgroundImage0 +
          ')',
      }"
    >
      <div :class="locale === 'zh' ? 'title-box-cn' : 'title-box-en'" class="title-box">
        <img :src="data.backgroundImage2" />
      </div>
      <div :class="locale === 'zh' ? 'logo-box-cn' : 'logo-box-en'" class="logo-box">
        <img :src="data.backgroundImage3" />
      </div>
      <div class="count-down-content">
        <!-- <div @click="hToUpload">
          {{ $t("UploadPortfolios") }}
        </div> -->
        <button
          class="button-type1 button"
          :style="{ minWidth: data.isMobile ? '260px' : '260px', backgroundColor: locale === 'en' ? 'rgba(200,200,200,.6)' : 'rgba(26,26,26,.25)', fontSize: '16px' }"
          v-if="data.type == 1"
        >
          {{ $t("NotSubmissionPeriodYet") }}
        </button>
        <button
          :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
          class="button-type5 button"
          v-if="data.type == 2 && locale === 'en' && !data.isMobile"
          @click="hToUpload"
        >
          {{ $t("UploadPortfolios") }}
        </button>
        <button
          :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
          class="button-type4 button"
          v-if="data.type == 2 && locale === 'zh' && !data.isMobile"
          @click="hToUpload"
        >
          {{ $t("UploadPortfolios") }}
        </button>
        <button
          :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
          class="button-type2 button"
          v-if="data.type == 2 && data.isMobile"
          @click="hToUpload"
        >
          {{ $t("UploadPortfolios") }}
        </button>
        <button
          :style="{ minWidth: data.isMobile ? '260px' : '260px', backgroundColor: locale === 'en' ? 'rgba(200,200,200,.6)' : 'rgba(26,26,26,.25)', fontSize: '16px' }"
          class="button-type3 button"
          v-if="data.type == 3"
        >
          {{ $t("TheSubmissionPeriodIsOver") }}
        </button>
        <p v-show="data.type == 3" :style="{color: locale === 'en'? '#b7b5bc' : '#000000d9'}">{{ $t("PleaseLookForwardTo") }}</p>
        <p v-show="data.type == 2" :style="{color: locale === 'en'? '#b7b5bc' : '#000000d9'}">{{ $t("SubmissionDue") }}</p>
      </div>
    </article>
    <div v-if="data.isMobile" class="content-mobile">
      <img style="width: 100%; margin-bottom: 20px" :src="data.backgroundImage1" alt='' />
      <button
        class="button-type1 button"
        :style="{ minWidth: data.isMobile ? '260px' : '260px', backgroundColor: locale === 'en' ? 'rgba(200,200,200,.6)' : 'rgba(26,26,26,.25)', fontSize: '16px' }"
        v-if="data.type == 1"
      >
        {{ $t("NotSubmissionPeriodYet") }}
      </button>
      <button
        :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
        class="button-type5 button"
        v-if="data.type == 2 && locale === 'en' && !data.isMobile"
        @click="hToUpload"
      >
        {{ $t("UploadPortfolios") }}
      </button>
      <button
        :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
        class="button-type4 button"
        v-if="data.type == 2 && locale === 'zh' && !data.isMobile"
        @click="hToUpload"
      >
        {{ $t("UploadPortfolios") }}
      </button>
      <button
        :style="{ minWidth: data.isMobile ? '260px' : '260px', fontSize: '16px' }"
        class="button-type2 button"
        v-if="data.type == 2 && data.isMobile"
        @click="hToUpload"
      >
        {{ $t("UploadPortfolios") }}
      </button>
      <button
        :style="{ minWidth: data.isMobile ? '260px' : '260px', backgroundColor: locale === 'en' ? 'rgba(200,200,200,.6)' : 'rgba(26,26,26,.25)', fontSize: '16px' }"
        class="button-type3 button"
        v-if="data.type == 3"
      >
        {{ $t("TheSubmissionPeriodIsOver") }}
      </button>
      <p v-show="data.type == 3" :style="{color: locale === 'en'? '#b7b5bc' : '#000000d9', marginTop: '20px'}">{{ $t("PleaseLookForwardTo") }}</p>
      <p v-show="data.type == 2" :style="{color: locale === 'en'? '#b7b5bc' : '#000000d9', marginTop: '20px'}">{{ $t("SubmissionDue") }}</p>
    </div>
    <!-- PC倒计时 end -->
    <!-- PC赛事更新 start -->
    <article class="event-update">
      <div class="event-update-box">
        <div class="event-update-introduce" :class="locale === 'en' ? 'localeEn' : 'localeZh'">
          <h2>{{ $t("ThisTournament") }}</h2>
          <div v-if="data.isMobile && locale === 'zh'">
            <p style="padding-bottom: 10px">
              {{ $t("CompetitionText1") }}
              {{ $t("CompetitionText2") }}
            </p>
            <p style="padding-bottom: 10px">
              {{ $t("CompetitionText3") }}
              {{ $t("CompetitionText4") }}
            </p>
            <p>
              {{ $t("CompetitionText5") }}
            </p>
            <p>
              {{ $t("CompetitionText6") }}
            </p>
            <p>
              {{ $t("CompetitionText7") }}
            </p>
          </div>
          <div v-else>
            <p>
              {{ $t("CompetitionText1") }}
            </p>
            <p>
              {{ $t("CompetitionText2") }}
            </p>
            <p>
              {{ $t("CompetitionText3") }}
            </p>
            <p>
              {{ $t("CompetitionText4") }}
            </p>
            <p>
              {{ $t("CompetitionText5") }}
            </p>
            <p>
              {{ $t("CompetitionText6") }}
            </p>
            <p>
              {{ $t("CompetitionText7") }}
            </p>
          </div>
          <p style="margin-top: 20px !important">
            {{ $t("CompetitionText84") }}
          </p>
          <!-- <div @click="hTaskBoke(true)">{{ $t("DesignTask") }}</div> -->
          <button class="button" style="min-width: 0" @click="hTaskBoke(true)">
            {{ $t("DesignTask") }}
          </button>
        </div>
        <div class="event-update-title">
          <video-pc :videoSrc="data.commonVideoSrc" />
        </div>
      </div>
    </article>
    <!-- PC赛事更新 end -->
    <!-- PC优秀作品 start-->
    <main class="excellent-works-PC" id="tabBox">
      <div class="tab-box">
        <div class="tab-box-main">
          <a-tabs
            ref="tabRef"
            v-model:activeKey="data.activeKeyPC"
            @Change="tsbsOnChange"
          >
            <template #leftExtra>
              <div class="tab-box-title">
                <span ref="worksPc">{{ $t("PreviousExcellentWorks") }}</span>
              </div>
            </template>
            <template #rightExtra>
              <treeSelect
                @hScreen="hScreen"
                :treeData="data.treeData"
                :raceTrack="data.RaceTrack"
              ></treeSelect>
            </template>
            <a-tab-pane key="1" tab="2023" force-render>
              <div
                class="item-box"
                v-for="(item, index) in data.dataListPC"
                :key="index"
                @click="hVideoPC(item, false)"
              >
                <div class="item-image">
                  <img :src="item.src[0]" alt="" class="basic" />
                  <img
                    :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                    alt=""
                    class="bear-the-palm"
                    :style="{ width: item.awardsSrc == 5 ? '117px' : '82px' }"
                  />
                </div>
                <h3>{{ item.workName }}</h3>
                <p>
                  {{ item.awards }} | {{ item.name }} | {{ item.country
                  }}{{ item.category != "欧洲" ? " • " : ""
                  }}{{ item.category != "欧洲" ? item.category : "" }}
                </p>
              </div>
              <div class="empty" v-if="!data.dataListPC.length">
                <img src="@/assets/empty.png" alt="" />
                <span> 暂无作品 </span>
              </div>
              <div v-else style="width: 22%"></div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="2022" force-render>
              <div
                class="item-box"
                v-for="(item, index) in data.dataListPC"
                :key="index"
                @click="hVideoPC(item, false)"
              >
                <div class="item-image">
                  <img :src="item.src[0]" alt="" class="basic" />
                  <img
                    :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                    alt=""
                    class="bear-the-palm"
                    :style="{ width: item.awardsSrc == 5 ? '117px' : '82px' }"
                  />
                </div>
                <h3>{{ item.workName }}</h3>
                <p>
                  {{ item.awards }} | {{ item.name }} | {{ item.country
                  }}{{ item.category != "欧洲" ? " • " : ""
                  }}{{ item.category != "欧洲" ? item.category : "" }}
                </p>
              </div>
              <div class="empty" v-if="!data.dataListPC.length">
                <img src="@/assets/empty.png" alt="" />
                <span> 暂无作品 </span>
              </div>
              <div v-else style="width: 22%"></div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="2021">
              <div
                class="item-box"
                v-for="(item, index) in data.dataListPC"
                :key="index"
                @click="hVideoPC(item, false)"
              >
                <div class="item-image">
                  <img :src="item.src[0]" alt="" class="basic" />
                  <img
                    :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                    alt=""
                    class="bear-the-palm"
                    :style="{ width: item.awardsSrc == 5 ? '117px' : '82px' }"
                  />
                </div>
                <h3>{{ item.workName }}</h3>
                <p>
                  {{ item.awards }} | {{ item.name }} | {{ item.country
                  }}{{ item.category ? " • " : "" }}{{ item.category }}
                </p>
              </div>
              <div class="empty" v-if="!data.dataListPC.length">
                <img src="@/assets/empty.png" alt="" />
                <span> 暂无作品 </span>
              </div>
              <div v-else style="width: 22%"></div>
            </a-tab-pane>
            <a-tab-pane key="4" tab="2020">
              <div
                class="item-box"
                v-for="(item, index) in data.dataListPC"
                :key="index"
                @click="hVideoPC(item, false)"
              >
                <div class="item-image">
                  <img :src="item.src[0]" alt="" class="basic" />
                  <img
                    :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                    alt=""
                    class="bear-the-palm"
                    :style="{ width: item.awardsSrc == 5 ? '117px' : '82px' }"
                  />
                </div>
                <h3>{{ item.workName }}</h3>
                <p>
                  {{ item.awards }} | {{ item.name }} | {{ item.country
                  }}{{ item.category ? " • " : "" }}{{ item.category }}
                </p>
              </div>
              <div class="empty" v-if="!data.dataListPC.length">
                <img src="@/assets/empty.png" alt="" />
                <span> 暂无作品 </span>
              </div>
              <div v-else style="width: 22%"></div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <!-- <div class="main-box">

      </div> -->
    </main>
    <!-- PC优秀作品 end-->
    <!-- Mobel优秀作品 start-->
    <main class="excellent-works-M">
      <div class="tab-box-title">
        <span ref="worksM">{{ $t("PreviousExcellentWorks") }}</span>
        <div class="screen-M" @click="data.treeDataM.visible = true">
          <span>{{ $t("Screen") }}</span>
          <img src="@/assets/arrows_bottom.png" alt="" />
        </div>
      </div>
      <nut-cascader
        :title="data.TrackSelection"
        v-model:visible="data.treeDataM.visible"
        @change="events.change"
        :options="data.treeDataM.options"
      ></nut-cascader>
      <nut-tabs
        v-model="data.activeKeyM"
        title-scroll
        :background="'#fff'"
        :color="'#000'"
        :size="'smail'"
        :title-gutter="10"
        @Change="hChangeM"
        ref="tabsRefM"
      >
        <nut-tabpane title="2023">
          <div class="video-box-M">
            <div
              class="item-box"
              v-for="(item, index) in data.dataListM"
              @click="hVideoM(item)"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.src[0]" alt="" class="basic" />
                <img
                  :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                  alt=""
                  class="bear-the-palm"
                  :style="{ width: item.awardsSrc == 5 ? '110px' : '82px' }"
                />
              </div>
              <h3>{{ item.workName }}</h3>
              <!--              <p>-->
              <!--                {{ item.awards }} | {{ item.name }} | {{ item.country-->
              <!--                }}{{ item.category ? " • " : "" }}{{ item.category }}-->
              <!--              </p>-->
            </div>
          </div>
          <div class="empty" v-if="!data.dataListM.length">
            <img src="@/assets/empty.png" alt="" />
            <span> 暂无作品 </span>
          </div>
        </nut-tabpane>
        <nut-tabpane title="2022">
          <div class="video-box-M">
            <div
              class="item-box"
              v-for="(item, index) in data.dataListM"
              @click="hVideoM(item)"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.src[0]" alt="" class="basic" />
                <img
                  :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                  alt=""
                  class="bear-the-palm"
                  :style="{ width: item.awardsSrc == 5 ? '110px' : '82px' }"
                />
              </div>
              <h3>{{ item.workName }}</h3>
              <!--              <p>-->
              <!--                {{ item.awards }} | {{ item.name }} | {{ item.country-->
              <!--                }}{{ item.category ? " • " : "" }}{{ item.category }}-->
              <!--              </p>-->
            </div>
          </div>
          <div class="empty" v-if="!data.dataListM.length">
            <img src="@/assets/empty.png" alt="" />
            <span> 暂无作品 </span>
          </div>
        </nut-tabpane>
        <nut-tabpane title="2021">
          <div class="video-box-M">
            <div
              class="item-box"
              v-for="(item, index) in data.dataListM"
              @click="hVideoM(item)"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.src[0]" alt="" class="basic" />
                <img
                  :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                  alt=""
                  class="bear-the-palm"
                  :style="{ width: item.awardsSrc == 5 ? '110px' : '82px' }"
                />
              </div>
              <h3>{{ item.workName }}</h3>
              <!--              <p>-->
              <!--                {{ item.awards }} | {{ item.name }} | {{ item.country-->
              <!--                }}{{ item.category ? " • " : "" }}{{ item.category }}-->
              <!--              </p>-->
            </div>
            <div class="empty" v-if="!data.dataListM.length">
              <img src="@/assets/empty.png" alt="" />
              <span> 暂无作品 </span>
            </div>
          </div>
        </nut-tabpane>
        <nut-tabpane title="2020">
          <div class="video-box-M">
            <div
              class="item-box"
              v-for="(item, index) in data.dataListM"
              @click="hVideoM(item)"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.src[0]" alt="" class="basic" />
                <img
                  :src="data.resOther.data[item.awardsSrc + 1]['extranetUrl']"
                  alt=""
                  class="bear-the-palm"
                  :style="{ width: item.awardsSrc == 5 ? '110px' : '82px' }"
                />
              </div>
              <h3>{{ item.workName }}</h3>
              <!--              <p>-->
              <!--                {{ item.awards }} | {{ item.name }} | {{ item.country-->
              <!--                }}{{ item.category ? " • " : "" }}{{ item.category }}-->
              <!--              </p>-->
            </div>
            <div class="empty" v-if="!data.dataListM.length">
              <img src="@/assets/empty.png" alt="" />
              <span> 暂无作品 </span>
            </div>
          </div>
        </nut-tabpane>
      </nut-tabs>
    </main>
    <!-- Mobel优秀作品 end-->
    <!-- PC底部 start -->
    <footer-layout :src="data.commonSrc && data.commonSrc"></footer-layout>
    <!-- PC底部 end -->
    <!-- 作品详情 start -->
    <article class="works-detail" v-if="data.isVideo" @click="cVideoPC">
      <!-- <span class="icon-X" @click="cVideoPC">X</span> -->
      <img class="icon-X" src="@/assets/v-close.png" @click="cVideoPC" />
    </article>
    <div class="video-detail">
      <div class="header"></div>
      <div v-if="!data.isTaskBokes" class="detail-box">
        <h2>{{ data.workDetail.workName }}</h2>
        <div class="works-title-box">
          <img
            v-if="data.workDetail.awards == 1"
            src="@/assets/medal1.png"
            alt=""
          />
          <img
            v-if="data.workDetail.awards == 2"
            src="@/assets/medal2.png"
            alt=""
          />
          <img
            v-if="data.workDetail.awards == 3"
            src="@/assets/medal3.png"
            alt=""
          />
          <img
            v-if="data.workDetail.awards == 4"
            src="@/assets/medal4.png"
            alt=""
          />
          <img
            v-if="data.workDetail.awards == 5"
            style="width: 110px"
            src="@/assets/medal5.png"
            alt=""
          />
          <img
            v-if="data.workDetail.awards == 31"
            src="@/assets/medal6.png"
            alt=""
          />
          <span
          >{{ data.workDetail.name }} |
            {{ data.workDetail.countryCategory }}</span
          >
        </div>
        <div class="video-box">
          <!-- <div class="vido-box-overflow"> -->
          <div class="video-item" v-for="(ele, i) in data.videoList" :key="i">
            <video-pc
              ref="videoRef"
              v-if="ele.isVideo != -1"
              :videoSrc="ele['extranetUrl']"
            />
            <img v-if="ele.isIMG != -1" :src="ele['extranetUrl']" alt="" />
            <img v-if="ele.isPNG != -1" :src="ele['extranetUrl']" alt="" />
            <img v-if="ele.isGif != -1" :src="ele['extranetUrl']" alt="" />
          </div>
          <div style="width: 100%; height: 84px; background: #fff"></div>
          <!-- </div> -->
        </div>
      </div>
      <designTaskPC
        :videoSrc="data.commonVideoSrc"
        :isLang="data.isLang"
        v-else
      />
    </div>
    <!-- 作品放大 -->
    <a-image
      v-if="data.current"
      :style="{ display: 'none' }"
      :preview="{ visible, onVisibleChange: hDrawer }"
      :src="data.current"
    />
    <nut-popup
      :style="{ padding: '16px', background: 'rgba(0,0,0,0.8)', color: '#fff' }"
      v-model:visible="data.showBasic"
    >{{ $t("Hint") }}</nut-popup
    >
  </div>
</template>

<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  ref,
  computed,
  nextTick,
} from "vue";
import headerPhone from "@/components/header-phone.vue";
import footerLayout from "@/components/footer.vue";
import treeSelect from "@/components/treeSelect.vue";
import designTaskPC from "@/views/designTaskPC.vue";
import videoPc from "@/components/video-PC.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { yearVideoList } from "@/assets/videoJson/videoJson.json";
import API from "../api/index.js";
const router = useRouter();
const { t, locale } = useI18n();
const data = reactive({
  // 是否固定定位
  fixed: false,
  // 可视区域滚动距离
  scrollTop: 0,
  // language: navigator.language.split("-")[1],
  // tab页PC key
  activeKeyPC: "1",
  // tab页M key
  activeKeyM: "0",
  //历届优秀作品dom
  worksDom: [],
  oneHeight: null,
  // PC 筛选数据
  treeData: [
    {
      label: "主赛道",
      id: 1,
    },
    {
      label: "CMF设计",
      id: 2,
    },
    {
      label: "数字可视化设计",
      id: 3,
    },
    {
      label: "品牌研究与策略设计",
      id: 4,
    },
    {
      label: "HMI设计",
      id: 5,
    },
    {
      label: "全部",
      id: 0,
    },
  ],
  workId: null,
  // M 筛选数据
  treeDataM: {
    visible: false,
    value: ["主赛道"],
    options: [
      {
        value: t("StylingDesign"),
        text: t("StylingDesign"),
        id: 1,
      },
      {
        value: t("ColorMaterial"),
        text: t("ColorMaterial"),
        id: 2,
      },
      {
        value: t("DigitalVisualizationDesign"),
        text: t("DigitalVisualizationDesign"),
        id: 3,
      },
      {
        value: t("BrandResearchDesignStrategy"),
        text: t("BrandResearchDesignStrategy"),
        id: 4,
      },
      {
        value: t("HumanMachineInterfaceDesign"),
        text: t("HumanMachineInterfaceDesign"),
        id: 5,
      },
      {
        value: t("All"),
        text: t("All"),
        id: 0,
      },
    ],
  },
  isVideo: false,
  videoList: [],
  yearListPC: [
    { year: 2023, yearText: "2023 年" },
    { year: 2022, yearText: "2022 年" },
    { year: 2021, yearText: "2021 年" },
    { year: 2020, yearText: "2020 年" },
  ],
  dataListPC: [],
  dataListM: [],
  workDetail: {
    name: "",
    countryCategory: "",
    awards: "",
    workName: "",
  },
  queryListPC: [],
  queryListM: [],
  copyYearVideoList: [],
  commonSrc: null,
  logo: "", // logo图片
  weibo: "",
  weixin: "",
  resOther: null,
  isTaskBokes: false,
  current: "",
  title: "",
  isMobile: Boolean,
  backgroundImage0: "",
  backgroundImage1: "",
  backgroundImage2: "",
  backgroundImage3: "",
  gifUrl: "",
  RaceTrack: "赛道",
  commonVideoSrc: "",
  showBasic: false,
  isLang: true,
  type: null,
  TrackSelection: "赛道选择",
});

// const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));
const headerPc = ref(); // 头部ref
const worksPc = ref(); // 最新作品ref
const tabRef = ref(); // tabs ref
const tabsRefM = ref();
const visible = ref(false);
const videoRef = ref(null);
// watch(value, () => {
// });
watch(
  () => [data.scrollTop, locale.value],
  (newValue, oldValue) => {
    if (document.documentElement.clientWidth >= 750) {
      if (
        newValue[0] > data.oneHeight - 44 &&
        oldValue[0] <= data.oneHeight - 44
      ) {
        headerPc.value.style = `background-color:#fff;transition: all 0.5s;`;
      }
      if (
        newValue[0] < data.oneHeight - 35 &&
        oldValue[0] >= data.oneHeight - 35
      ) {
        headerPc.value.style = `background-color:transparent;transition: all 0.5s;`;
      }
      // 头部动画往下移动
      if (
        newValue[0] >
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight -
        44 &&
        oldValue[0] <=
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight -
        44
      ) {
        headerPc.value.style = `position:absolute;top:${
          data.oneHeight +
          document.querySelectorAll(".event-update")[0].offsetHeight -
          44
        }px;background:#fff`;
      }

      // 头部动画往上移动
      if (
        newValue[0] <
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight -
        44 &&
        oldValue[0] >=
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight -
        44
      ) {
        worksPc.value.style = `font-size:32px`;
        headerPc.value.style = `top: 0;position: fixed;background-color:#fff`;
        // headerPc.value.style = `display:block`;
      }
      //历届优秀作品动画往下移动
      if (
        newValue[0] >
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight &&
        oldValue[0] <=
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight
      ) {
        worksPc.value.style = `font-size:16px;position:fixed;top:0;height:40px`;
        document.querySelectorAll(".tab-box-title")[0].style.cssText =
          "height:30px";
      }
      //历届优秀作品动画往上移动
      if (
        newValue[0] <
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight &&
        oldValue[0] >=
        data.oneHeight +
        document.querySelectorAll(".event-update")[0].offsetHeight
      ) {
        worksPc.value.style = `font-size:32px;height:60px`;

        document.querySelectorAll(".tab-box-title")[0].style.cssText =
          "height:60px";
      }
    }
    if (newValue[1] == "en") {
      if (document.documentElement.clientWidth > 750) {
        data.backgroundImage0 = data.resOther.data[21]["extranetUrl"];
        data.backgroundImage1 = data.resOther.data[21]["extranetUrl"];
        data.backgroundImage2 = data.resOther.data[22]["extranetUrl"];
        data.backgroundImage3 = data.resOther.data[23]["extranetUrl"];

      } else {
        data.backgroundImage0 = data.resOther.data[24]["extranetUrl"];
        data.backgroundImage1 = data.resOther.data[24]["extranetUrl"];
      }
      data.treeDataM = {
        visible: false,
        value: ["Styling Design (Main)"],
        options: [
          {
            value: "Styling Design (Main)",
            text: "Styling Design (Main)",
            id: 1,
          },
          {
            value: "Brand Research & Design Strategy",
            text: "Brand Research & Design Strategy",
            id: 2,
          },
          {
            value: "CMF (Color, Material & Finishing) Design",
            text: "CMF (Color, Material & Finishing) Design",
            id: 3,
          },
          {
            value: "Digital Visualization Design",
            text: "Digital Visualization Design",
            id: 4,
          },
          {
            value: "Human-machine Interface Design",
            text: "Human-machine Interface Design",
            id: 5,
          },
          {
            value: "All",
            text: "All",
            id: 0,
          },
        ],
      };
      data.TrackSelection = "Track Selection";
    } else {
      if (document.documentElement.clientWidth > 750) {
        data.backgroundImage0 = data.resOther.data[17]["extranetUrl"];
        data.backgroundImage1 = data.resOther.data[17]["extranetUrl"];
        data.backgroundImage2 = data.resOther.data[18]["extranetUrl"];
        data.backgroundImage3 = data.resOther.data[19]["extranetUrl"];
      } else {
        data.backgroundImage0 = data.resOther.data[20]["extranetUrl"];
        data.backgroundImage1 = data.resOther.data[20]["extranetUrl"];
      }
      data.treeDataM = {
        visible: false,
        value: ["主赛道"],
        options: [
          {
            value: "主赛道",
            text: "主赛道",
            id: 1,
          },
          {
            value: "CMF设计",
            text: "CMF设计",
            id: 2,
          },
          {
            value: "数字可视化设计",
            text: "数字可视化设计",
            id: 3,
          },
          {
            value: "品牌研究与策略设计",
            text: "品牌研究与策略设计",
            id: 4,
          },
          {
            value: "HMI设计",
            text: "HMI设计",
            id: 5,
          },
          {
            value: "全部",
            text: "全部",
            id: 0,
          },
        ],
      };
      data.TrackSelection = "赛道选择";
    }
  }
);

onMounted(async () => {
  window.addEventListener("scroll", data.scrolling);
  data.worksDom = document.querySelectorAll(".ant-tabs-extra-content");
  data.worksDom[0].className = "ant-tabs-extra-content work-title";
  data.worksDom[1].className = "ant-tabs-extra-content work-select";
  if (document.querySelectorAll("#count-down") && document.querySelectorAll("#count-down").length) {
    data.oneHeight = document.querySelectorAll("#count-down")[0].offsetHeight;
  }
  fetchComonImage();
  data.copyYearVideoList = JSON.parse(JSON.stringify(yearVideoList));
  data.isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  data.resOther = await API.requestImage({
    fileDirUrl: "SAIC_Design/prod/other/",
  }).catch((e) => e);
  if (document.documentElement.clientWidth > 750) {
    data.backgroundImage1 =
      locale.value != "en"
        ? data.resOther.data[17]["extranetUrl"]
        : data.resOther.data[21]["extranetUrl"];
    data.backgroundImage0 =
      locale.value != "en"
        ? data.resOther.data[17]["extranetUrl"]
        : data.resOther.data[21]["extranetUrl"];
  } else {
    data.backgroundImage1 =
      locale.value != "en"
        ? data.resOther.data[20]["extranetUrl"]
        : data.resOther.data[24]["extranetUrl"];
    data.backgroundImage0 =
      locale.value != "en"
        ? data.resOther.data[20]["extranetUrl"]
        : data.resOther.data[24]["extranetUrl"];
  }
  data.backgroundImage2 =
    locale.value != "en"
      ? data.resOther.data[18]["extranetUrl"]
      : data.resOther.data[22]["extranetUrl"];
  data.backgroundImage3 = locale.value != "en" ? data.resOther.data[19]["extranetUrl"] : data.resOther.data[23]["extranetUrl"];
  data.gifUrl = data.resOther.data[13]["extranetUrl"];
  data.commonVideoSrc = data.resOther.data[12]["extranetUrl"];
  const resCommon = await API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).catch((e) => e);
  data.logo = resCommon.data[0]["extranetUrl"];
  data.weibo = resCommon.data[2]["extranetUrl"];
  data.weixin = resCommon.data[3]["extranetUrl"];
  if (data.isMobile) {
    getImageM(
      {
        fileDirUrl: "SAIC_Design/prod/2023/",
      },
      true
    );
    hGetTime();
  } else {
    getImagePC(
      {
        fileDirUrl: "SAIC_Design/prod/2023/",
      },
      true
    );
    hGetTime();
  }
});

const handleJump = () => {
  window.open('https://saicdesignchallenge.com', '_blank')
}

const fetchComonImage = () => {
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).then((res) => {
    data.commonSrc = res.data;
  });
};
// 图片列表在线接口
const getImagePC = async (obj, isMounted) => {
  // data.dataListPC = [];
  const res = await API.requestImage(obj).catch((e) => e);
  data.queryListPC = res.data;
  if (isMounted) {
    data.queryListPC.forEach((element) => {
      yearVideoList.forEach((item, index) => {
        if (
          item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListPC.push(data.copyYearVideoList[index]);
        }
      });
    });
  } else {
    data.dataListPC = [];
    data.queryListPC.forEach((element) => {
      yearVideoList.forEach((item, index) => {
        if (
          item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year &&
          !data.workId &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListPC.push(data.copyYearVideoList[index]);
        } else if (
          item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year &&
          item.id == data.workId &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListPC.push(data.copyYearVideoList[index]);
        }
      });
    });
  }
};

// 获取时间状态
const hGetTime = async () => {
  const res = await API.getTime().catch((e) => e);
  data.type = res.data;
};

const hTaskBoke = (isTaskBokes) => {
  if (!data.isMobile) {
    data.isVideo = true;
    data.isTaskBokes = isTaskBokes;
    document.querySelectorAll("body")[0].style.cssText = "overflow:hidden";
    document.querySelectorAll(".video-detail")[0].style.cssText = "bottom:0vh";
  } else {
    router.push({
      path: "/designTask",
      query: { videoSrc: JSON.stringify(data.commonVideoSrc) },
    });
  }
};

// 筛选事件
const hScreen = (value) => {
  data.dataListPC = [];
  data.workId = value;
  data.queryListPC.forEach((element) => {
    yearVideoList.forEach((item, index) => {
      if (
        item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year &&
        element["extranetUrl"].indexOf(item.src[0]) != -1
      ) {
        data.copyYearVideoList[index].src[0] = element["extranetUrl"];
      }
    });
  });
  data.copyYearVideoList.forEach((item) => {
    if (
      !value &&
      item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year
    ) {
      data.dataListPC.push(item);
    } else if (
      item.id == value &&
      item.annual == data.yearListPC[Number(data.activeKeyPC) - 1].year
    )
      data.dataListPC.push(item);
  });
};

// tabs切换事件
const tsbsOnChange = (key) => {
  // data.dataListPC = [];
  let tabBox = document.querySelector("#tabBox");
  tabBox.scrollIntoView();
  getImagePC({
    fileDirUrl: `SAIC_Design/prod/${data.yearListPC[Number(key) - 1].year}/`,
  });
};

// 视频点击事件
const hVideoPC = async (i, isTaskBokes) => {
  const res = await API.requestImage({
    fileDirUrl: `SAIC_Design/prod/${
      data.yearListPC[Number(data.activeKeyPC) - 1].year
    }${i.cloneSrc}/`,
  }).catch((e) => e);
  res.data.forEach((item, index) => {
    data.videoList.push({
      internalUrl: item["extranetUrl"],
      extranetUrl: item["extranetUrl"],
      isIMG: item["extranetUrl"].indexOf(".jpg"),
      isPNG: item["extranetUrl"].indexOf(".png"),
      isVideo: item["extranetUrl"].indexOf(".mp4"),
      isGif: item["extranetUrl"].indexOf("gif"),
    });
  });

  data.workDetail.name = i.name;
  data.workDetail.countryCategory = `${i.country} • ${i.category}`;
  data.workDetail.workName = i.workName;
  data.workDetail.awards = i.awardsSrc;
  data.isVideo = true;
  data.isTaskBokes = isTaskBokes;
  document.querySelectorAll("body")[0].style.cssText = "overflow:hidden";
  document.querySelectorAll(".video-detail")[0].style.cssText = "bottom:0vh";
};
// 视频关闭事件
const cVideoPC = () => {
  document.querySelectorAll("body")[0].style.cssText = "";
  document.querySelectorAll(".video-detail")[0].style.cssText =
    "bottom:-100vh;";
  data.isVideo = false;
  data.videoList = [];
  data.workDetail.name = "";
  data.workDetail.countryCategory = ``;
  data.workDetail.workName = "";
  data.isTaskBokes = false;
};

const hDrawer = (src, title) => {
  data.current = src;
  data.title = title;
  visible.value = true;
};

// H5端 视频点击事件
const hVideoM = (item) => {
  const detail = {
    annual: item.annual,
    awards: item.awards,
    awardsSrc: item.awardsSrc,
    category: item.category,
    country: item.country,
    id: item.id,
    name: item.name,
    src: item.src,
    workName: item.workName,
    cloneSrc: item.cloneSrc,
  };
  router.push({
    path: "/worksDetail",
    query: { detail: JSON.stringify(detail) },
  });
};

const getImageM = async (obj, isMounted) => {
  data.dataListM = [];
  const res = await API.requestImage(obj).catch((e) => e);

  data.queryListM = res.data;
  if (isMounted) {
    data.queryListM.forEach((element) => {
      yearVideoList.forEach((item, index) => {
        if (
          item.annual == data.yearListPC[Number(data.activeKeyM)].year &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListM.push(data.copyYearVideoList[index]);
        }
      });
    });
  } else {
    data.dataListM = [];
    data.queryListM.forEach((element) => {
      yearVideoList.forEach((item, index) => {
        if (
          item.annual == data.yearListPC[Number(data.activeKeyM)].year &&
          !data.workId &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListM.push(data.copyYearVideoList[index]);
        } else if (
          item.annual == data.yearListPC[Number(data.activeKeyM)].year &&
          item.id == data.workId &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
          data.copyYearVideoList[index].cloneSrc = item.src[0].slice(
            0,
            item.src[0].indexOf(".")
          );
          data.dataListM.push(data.copyYearVideoList[index]);
        }
      });
    });
  }
};

const events = {
  change(value, pathNodes) {
    data.dataListM = [];
    data.workId = pathNodes[0].id;
    data.queryListM.forEach((element) => {
      yearVideoList.forEach((item, index) => {
        if (
          item.annual == data.yearListPC[Number(data.activeKeyM)].year &&
          element["extranetUrl"].indexOf(item.src[0]) != -1
        ) {
          data.copyYearVideoList[index].src[0] = element["extranetUrl"];
        }
      });
    });
    data.copyYearVideoList.forEach((item) => {
      if (
        !data.workId &&
        item.annual == data.yearListPC[Number(data.activeKeyM)].year
      ) {
        data.dataListM.push(item);
      } else if (
        item.id == data.workId &&
        item.annual == data.yearListPC[Number(data.activeKeyM)].year
      )
        data.dataListM.push(item);
    });
  },
};
const hChangeM = (val) => {
  data.dataListM = [];
  getImageM({
    fileDirUrl: `SAIC_Design/prod/${
      data.yearListPC[Number(data.activeKeyM)].year
    }/`,
  });
};

// 国际化切换
const changeLang = (lang) => {
  locale.value = lang;
  if (lang == "zh") {
    data.treeData = [
      {
        label: "主赛道",
        id: 1,
      },
      {
        label: "CMF设计",
        id: 2,
      },
      {
        label: "数字可视化设计",
        id: 3,
      },
      {
        label: "品牌研究与策略设计",
        id: 4,
      },
      {
        label: "HMI设计",
        id: 5,
      },
      {
        label: "全部",
        id: 0,
      },
    ];

    data.RaceTrack = "赛道";
    data.isLang = true;
    data.backgroundImage1 = data.resOther.data[17]["extranetUrl"];
    data.backgroundImage0 = data.resOther.data[17]["extranetUrl"];
    data.backgroundImage2 = data.resOther.data[18]["extranetUrl"];
  } else {
    data.backgroundImage1 = data.resOther.data[21]["extranetUrl"];
    data.backgroundImage0 = data.resOther.data[21]["extranetUrl"];
    data.backgroundImage2 = data.resOther.data[22]["extranetUrl"];
    data.treeData = [
      {
        label: "Styling Design (Main)",
        id: 1,
      },
      {
        label: "Brand Research & Design Strategy",
        id: 2,
      },
      {
        label: "CMF (Color, Material & Finishing) Design",
        id: 3,
      },
      {
        label: "Digital Visualization Design",
        id: 4,
      },
      {
        label: "Human-machine Interface Design",
        id: 5,
      },
      {
        label: "All",
        id: 0,
      },
    ];
    data.RaceTrack = "Race Track";

    data.isLang = false;
  }
};

const hToUpload = () => {
  if (!data.isMobile) return router.push("/upload");
  data.showBasic = true;
};
</script>

<style lang="less" scoped>
// PC媒体查询适配 start
@media screen and (min-width: 1440px) {
  .count-down .count-down-content,
    // .count-down .count-down-popularize,
  .event-update .event-update-box,
  .excellent-works-PC .tab-box {
    max-width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1440px) and (min-width: 960px) {
  .count-down .count-down-content,
    // .count-down .count-down-popularize,
  .event-update .event-update-box,
  .excellent-works-PC .tab-box {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 960px) and (min-width: 750px) {
  .count-down .count-down-content,
    // .count-down .count-down-popularize,
  .event-update .event-update-box,
  .excellent-works-PC .tab-box {
    max-width: 959px;
    margin: 0 auto;
  }
}

// PC
@media screen and (min-width: 751px) {
  .empty {
    width: 100%;
    height: 50%;
    margin: 100px auto;
    text-align: center;
    img {
      display: block;
      width: 300px;
      height: 300px;
      margin: 0 auto;
    }
  }
  // PC端头部 start
  .layout-header-PC {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    padding: 0 24px;
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    z-index: 100;

    // backdrop-filter: blur(20px);
    // transition: all 0.5s;
    img {
      height: 44px;
      cursor: pointer;
    }

    span {
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .language-wrapper {
      height: 100%;
      position: absolute;
      top: 0;
      right: 24px;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .language-wrapper span {
      font-weight: bold;
      font-size: 14px;
    }
    .language-wrapper span:hover {
      color: #ED89B8 !important;
    }
    .language-wrapper span:active {
      color: #CC196D !important;
    }
    .language-wrapper span:first-child {
      margin-right: 26px;
    }
    .language-wrapper >button {
      border: 0;
      outline: none;
      width: 104px;
      height: 24px;
      border-radius: 52px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      color: #ffffff;
      background: #FE3998;
    }
    .language-wrapper >button:hover {
      background: #ED89B8;
    }
    .language-wrapper >button:active {
      background: #CC196D;
    }
  }

  // PC端头部 end

  // PC倒计时部分样式 start
  .count-down {
    text-align: center;
    background: repeat-x 0px 0px/100% 100%, repeat-x 0px 0px/100% 100%;
    width: 100%;
    height: 100vh;
    .logo-box-cn {
      position: absolute;
      top: 2%;
      left: 50%;
      transform: translate(-50%);
      width: 8%;
      img {
        width: 100%;
      }
    }
    .logo-box-en {
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translate(-50%);
      width: 400px;
      height: 20px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .title-box-cn {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%);
      height: 42%;
      img {
        height: 100%;
      }
    }
    .title-box-en {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .count-down-content {
      position: absolute;
      left: 50%;
      top: 86%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: transparent repeat-x 0px 0px/100% 100%;
      // margin-bottom: 3%;
      .button-type1,
      .button-type3 {
        background: rgba(26, 26, 26, 0.25);
        color: rgba(255, 255, 255, 0.25);
        cursor: not-allowed;
      }
      p {
        margin-top: 20px;
      }
    }

    .QR-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      top: 87%;
      left: 50%;
      transform: translateX(-50%);
      .code-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          padding: 3px;
          background: #fff;
          border: 1px solid #e2e2e2;
          border-radius: 2px;
        }
      }

      .code-item:hover img {
        border: 1px solid;
      }

      .code-item img {
        width: 64px;
        height: 64px;
      }

      .code-item span {
        font-size: 12px;
        line-height: 18px;
      }
    }

    // .count-down-popularize {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .count-down-popularize-content {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     // width: 280px;
    //     // height: 90px;
    //     .count-down-popularize-item {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: space-between;
    //       align-items: center;
    //       // height: 100%;
    //     }
    //     .center {
    //       margin: 0 15px;
    //     }
    //   }
    // }
  }
  .count-down-gif {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 55%; /* 如果高需要时宽度的百分比比例的话,可以这么设置 */
    .gif-box {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
      }
      .count-down-content {
        position: absolute;
        left: 50%;
        top: 86%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent repeat-x 0px 0px/100% 100%;
        // margin-bottom: 3%;
        .button-type1,
        .button-type3 {
          background: rgba(26, 26, 26, 0.25);
          color: rgba(255, 255, 255, 0.25);
          cursor: not-allowed;
        }
        p {
          margin-top: 20px;
        }
      }
    }
  }

  // PC倒计时部分样式 end

  // PC赛事更新 start
  .event-update {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;
    // height: 485px;
    padding: 50px 80px;

    .event-update-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      height: 100%;
      width: 100%;

      .event-update-introduce {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 40%;
        height: 100%;

        h2 {
          display: block;
          font-size: 32px;
          color: #000;
          margin-bottom: 24px !important;
          font-weight: 700;
        }

        p {
          font-size: 16px;
          // font-family: "AalibabaPuHui-B";
        }
        button {
          margin-top: 20px;
        }
        // div {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   background: #1a1a1a;
        //   color: #fff;
        //   border-radius: 54px;
        //   // width: 80px;
        //   // height: 25px;
        //   margin-top: 20px;
        //   padding: 8px 16px;
        //   cursor: pointer;
        //   transition: 0.5s all;
        // }

        // div:hover {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   border-radius: 54px;
        //   color: #fff;
        //   background: #000;
        //   margin-top: 20px;
        //   padding: 8px 16px;
        //   cursor: pointer;
        // }
      }

      .event-update-title {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }

  // PC赛事更新 end

  // PC优秀作品 start
  /deep/.excellent-works-PC {
    padding: 0 80px;
    width: 100%;
    background-color: #fff;

    .tab-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      // padding: 0 20px;
      .tab-box-main {
        width: 100%;

        .ant-tabs {
          overflow: visible !important;
          position: relative;

          .ant-tabs-nav {
            z-index: 5;
            flex-direction: column;
            position: sticky;
            width: 100%;
            top: 0;
            background-color: #fff;

            .work-title {
              width: 100%;

              .tab-box-title {
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  // font-family: "Alibaba PuHuiTi 2.0";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 45px;
                  // color: #000000;
                  transition: all 0.5s;
                }
              }
            }

            .work-select {
              position: absolute;
              right: 0;
              bottom: 5px;
            }
          }
        }

        .ant-tabs-tabpane {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          &::after {
            content: "";
            width: 22%;
          }

          .item-box:hover {
            transform: translateY(-5px);
            cursor: pointer;
          }

          .item-box {
            width: 22%;
            // height: 284px;
            margin-bottom: 40px;
            transition: all 0.5s;

            h3 {
              font-size: 16px;
              font-weight: 700;
            }

            p {
              font-size: 14px;
              // font-family: "AalibabaPuHui-B";
              color: rgba(0, 0, 0, 0.6);
            }

            .item-image {
              position: relative;
              width: 100%;
              height: 0;
              margin-bottom: 20px;
              padding-bottom: 75%;
              overflow: hidden;
              border-radius: 8px;
              border: 1px solid #EAEAEA;
              // background-color: #eaeaea;
              .basic {
                position: absolute;
                width: 100%;
                height: 100%;
                animation: reduce ease 0.5s forwards;
              }

              .bear-the-palm {
                position: absolute;
                width: 82px;
                height: 32px;
                left: 5px;
                bottom: 5px;
              }
            }

            &:hover .item-image > .basic {
              animation: amplify ease 0.5s forwards;
            }
          }
        }
      }
    }

    .tab-box .tab-box-main .item-box p:nth-child(1) {
      font-size: 16px;
      line-height: 22px;
    }

    .tab-box .tab-box-main .item-box p:nth-child(2) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  // PC优秀作品 end
  /deep/.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }

  /deep/.ant-tabs-ink-bar {
    background: #000;
    bottom: 5px !important;
  }

  /deep/ .ant-tabs-tab:hover {
    color: #000;
  }

  /deep/ .ant-tabs-top > .ant-tabs-nav::before {
    border: 0;
  }

  // PC作品详情 start
  .works-detail {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;

    animation-fill-mode: forwards;
    z-index: 101;

    .icon-X {
      position: absolute;
      right: 16px;
      top: 5vh;
      width: 24px;
      color: #fff;
      cursor: pointer;
    }
  }

  .video-detail {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    bottom: -100vh;
    height: 90%;
    width: 100%;
    background: #fff !important;
    z-index: 102;
    border-radius: 20px 20px 0 0;
    transition: all 0.3s;
    padding-top: 20px;

    .detail-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        margin: 30px 0;
      }

      .works-title-box {
        display: flex;
        align-items: center;
        margin: 33px 0;

        img {
          width: 82px;
          height: 32px;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .video-box {
        width: 80%;
        // padding: 0 10%;
        // height: calc(55% + 100px);

        .video-item {
          // position: relative;
          width: 100%;
          // height: auto;
          // background: #d9d9d9;
          // padding-bottom: 135%;
          margin-bottom: 16px;

          img {
            // position: absolute;
            width: 100%;
            // height: 100%;
          }
        }
      }
    }
  }

  // PC作品详情 end
  .excellent-works-M,
  .layout-header-M {
    display: none !important;
  }

  .ant-spin {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
}

// PC媒体查询适配 end

// Mobile媒体查询 start
@media screen and (max-width: 750px) {
  .empty {
    width: 100%;
    height: 50%;
    margin: 100px auto;
    text-align: center;
    img {
      display: block;
      width: 300px;
      height: 300px;
      margin: 0 auto;
    }
  }
  .nut-tabpane {
    padding: 0 !important;
  }
  /deep/.nut-icon-close:before,
  .nut-icon-checklist:before {
    content: "" !important;
  }
  .containner {
    width: 100% !important;
    height: 100% !important;
    padding-top: 44px;
    // margin-top: 44px;
  }
  .layout-header-PC,
  .video-detail,
  .excellent-works-PC {
    display: none !important;
  }

  // 第一页 start
  .count-down {
    // background-color: #dfdfdf;
    width: 100%;
    // height: 900px;
    height: 100%;
    background: repeat-x 0px 0px/100% 100%, repeat-x 0px 0px/100% 100%;
    .title-box {
      position: absolute;
      top: 13%;
      left: 50%;
      transform: translate(-50%);
      height: 20%;
      width: 93%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .count-down-content {
      position: absolute;
      left: 50%;
      top: 105%;
      transform: translate(-50%);
      color: #000;
      text-align: center;

      .button-type1,
      .button-type3 {
        background: rgba(26, 26, 26, 0.25);
        color: rgba(255, 255, 255, 0.25);
        cursor: not-allowed;
      }
      p {
        margin-top: 20px;
      }
    }
    .logo-box {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%);
      width: 140px;
      height: 150px;
      img {
        height: 100%;
      }
    }
    .QR-code {
      position: absolute;
      top: 72%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .code-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .code-item img {
        width: 64px;
        height: 64px;
        margin-bottom: 4px;
      }

      .code-item span {
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }
  .count-down-gif {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 85%; /*让子元素的高度 = 父元素的padding-bottom*/

    .gif-box {
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
      }
      .count-down-content {
        position: absolute;
        left: 50%;
        top: 86%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent repeat-x 0px 0px/100% 100%;
        // margin-bottom: 3%;
        .button-type1,
        .button-type3 {
          background: rgba(26, 26, 26, 0.25);
          color: rgba(255, 255, 255, 0.25);
          cursor: not-allowed;
        }
        p {
          margin-top: 20px;
        }
      }
      .count-down-gif {
      }
    }
  }

  // 第一页 end

  // 第二页 start
  .event-update {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;

    .event-update-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .event-update-introduce {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 90%;
        // height: 100%;
        h2 {
          display: block;
          font-size: 32px;
          line-height: 45px;
          color: #000;
          margin: 24px 0 !important;
        }

        p {
          font-size: 16px;
          // color: #000;
          margin: 5px 0 !important;
          // font-family: "AalibabaPuHui-B";
        }

        button {
          font-size: 16px;
          // font-family: "AalibabaPuHui-B";
        }
      }

      .event-update-title {
        width: 100%;
        margin: 20px 0;
        .video-right {
          width: 100%;
        }
      }
    }
  }

  // 第二页 end

  // 第三页 start
  /deep/.excellent-works-M {
    padding: 0 5%;
    width: 100%;
    background-color: #fff;
    .tab-box-title {
      position: relative;
      // height: 45px;
      text-align: center;

      span {
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        color: #000000;
      }

      .screen-M {
        position: absolute;
        right: 0;
        bottom: -35px;

        span {
          margin: 0 5px 0 0;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;

          color: #000;
        }

        img {
          width: 8px;
          height: 4px;
        }
      }
    }

    .nut-tab-pane {
      padding: 24px 0;
    }

    .nut-tabs__titles {
      padding: 0;
    }

    .nut-tabs__titles-item {
      margin: 0 !important;
    }

    .video-box-M {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item-box {
        width: 47%;
        // height: 284px;
        margin-bottom: 40px;

        .item-image {
          position: relative;
          width: 100%;
          height: 0;
          margin-bottom: 20px;
          padding-bottom: 75%;
          overflow: hidden;
          border-radius: 8px;
          border: 1px solid #EAEAEA;
          .basic {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .bear-the-palm {
            position: absolute;
            width: 82px;
            height: 32px;
            left: 5px;
            bottom: 5px;
          }
        }

        h3 {
          margin-top: 16px;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }

        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  // 第三页 end
  /deep/ .nut-tabs__titles {
    // background: #fff;
    width: 70%;
  }

  /deep/ .nut-tabs__titles-item.active .nut-tabs__titles-item__line {
    // background: #000;
    height: 1px;
    width: 40%;
    background: #000;
  }

  /deep/ .nut-cascader-item.active:not(.disabled) {
    color: #000;
  }

  /deep/ .nut-cascader-item.active .nut-cascader-item__icon-check {
    color: #000 !important;
  }

  /deep/ .nut-tabs__titles-item__text.ellipsis {
    overflow: visible !important;
  }
}

// Mobile媒体查询 end

// PC端固定定位
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff !important;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 1px 0 0 rgb(0 0 0 / 5%);
}

@keyframes amplify {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes reduce {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
.content-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button-type1,
.button-type3 {
  background: rgba(26, 26, 26, 0.25);
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}
.button-type4 {
  background-color: #FE3998 !important;
}
.button-type4:hover {
  background-color: #ED89B8 !important;
}
.button-type4:active {
  background-color: #CC196D !important;
}
.button-type5 {
  background-color: #B8C63C !important;
}
.button-type5:hover {
  background-color: #697333 !important;
}
.button-type5:active {
  background-color: #763345 !important;
}
:deep(.nut-tabs__titles-item__text) {
  text-align: left;
}
:deep(.nut-tabs__titles-item__line) {
  left: 25%;
}
:deep(.nut-popup .nut-tabs__titles-item__line) {
  left: 50%;
}
.localeEn {
  p {
    font-family: 'Aalibaba PuHui-A';
  }
}
.localeZh {
  p {
    font-family: 'AalibabaPuHui-A';
  }
}
</style>
