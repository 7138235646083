import request from '@/utils/axios.js'
export default {
    requestVideo: (data) => {
        return request({
            url: '/design/oss/file/preview/video/get',
            method: 'POST',
            data
        })
    },
    download: (data) => {
        return request({
            url: '/design/oss/file/oss/download',
            data,
            method: 'POST',
            responseType: 'blob',
            timeout: 1000 * 60 * 5,
        })
    },
    requestImage: (data) => {
        return request({
            url: '/design/oss/file/preview/images/get',
            method: 'POST',
            data
        })
    },
    uploadFile: (data) => {
        return request({
            url: '/design/oss/file/oss/upload',
            method: 'POST',
            data,
            timeout: 1000 * 60 * 5,
        })
    },
    submit: (data) => {
        return request({
            url: '/design/player/submit',
            method: 'POST',
            data,
        })
    },
    detail: (data) => {
        return request({
            url: '/design/player/detail',
            method: 'POST',
            data,
        })
    },
    getTime:() => {
        return request({
            url: '/design/player/deliver',
            method: 'POST',
            data:""
        })
    },
    ossInfo: () => {
        return request({
            url:'/design/oss/file/sts/secret/get',
            method: 'GET'
        })
    },
    getIpAdress: () => {
        return request({
            url: '/design/player/getIpAddress',
            method: 'GET'
        })
    }
}
