<template>
  <div>
    <a-select
      :placeholder="raceTrack"
      :dropdownStyle="{ minWidth: '300px' }"
      :dropdownClassName="'select-dow'"
      :bordered="false"
      style="width: 120px"
      @change="handleChange"
    >
      <a-select-option
        v-for="(item, index) in treeData"
        :value="item.id"
        :key="index"
      >
        {{ item.label }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script setup>
import { defineProps, ref, reactive, onMounted } from "vue";
const emit = defineEmits(["hScreen"]);
const props = defineProps({
  treeData: {
    type: Array,
    default: () => {
      return [];
    },
  },
  raceTrack: {
    type: String,
    default: () => {
      return "";
    },
  },
});
const data = reactive({});
const handleChange = (value) => {
  emit("hScreen", value);
};
onMounted(() => {});
</script>

<style lang="less" scoped>
/deep/ .ant-select {
  background: #f2f2f2;
  border-radius: 8px;
  height: 30px;
  .ant-select-selector {
    height: 100% !important;
  }
}
</style>
