<template>
  <header class="layout-header-M fixed">
    <div class="back" @click="hBack" v-if="props.isGoBack">
      <img src="@/assets/back.png" />
      <span>{{ $t("Back") }}</span>
    </div>
    <span v-else></span>
    <div class="image-wrapper">
      <a-spin :spinning="!props.src" :indicator="indicator">
        <img :src="props.src && props.src" />
      </a-spin>
    </div>
    <span @click="handle">•••</span>
    <div class="dialog-mask"></div>
    <div class="dialog-wrapper">
      <div class="nav-bar">
        <slot name="nav"></slot>
        <a>
          <span>{{ $t("Language") }}</span>
          <!-- <span>
            {{ data.language === "zh" ? "CN" : "EN" }}
            <a-switch
              v-model:checked="data.checked"
              @change="changeLang(data.language === 'zh' ? 'en' : 'zh')"
            />
          </span> -->
          <button @click="handleJump">Enter Europe</button>
        </a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useRouter } from "vue-router";
import {reactive, onMounted, onBeforeUnmount, inject} from "vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();

const router = useRouter();
const indicator = inject('indicator')
const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    default: "CN",
  },
  isGoBack: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  open: false,
  active: null,
  checked: false,
  language: locale.value,
});

const changeLang = (lang) => {
  data.open = false;
  locale.value = lang;
  data.language = lang;
  localStorage.setItem("saic-design-lang", lang);
  setTimeout(() => {
    let mask = document.querySelector(".dialog-mask");
    let vnode = document.querySelector(".dialog-wrapper");
    mask.style.transition = "all .4s";
    vnode.style.right = "-80%";
    mask.style.right = "-100%";
  }, 200);
};

onMounted(() => {
  locale.value === "en" && (data.checked = true);
  locale.value === "zh" && (data.checked = false);
});

onBeforeUnmount(() => {
  let mask = document.querySelector(".dialog-mask");
  let vnode = document.querySelector(".dialog-wrapper");
  mask.ontouchstart = null;
  mask.ontouchmove = null;
  vnode.ontouchmove = null;
});

const handle = () => {
  data.open = !data.open;
  let mask = document.querySelector(".dialog-mask");
  let vnode = document.querySelector(".dialog-wrapper");
  mask.ontouchmove = (e) => {
    e.preventDefault();
  };
  vnode.ontouchmove = (e) => {
    e.preventDefault();
  };
  mask.ontouchstart = () => {
    data.open = false;
    mask.style.transition = "all .4s";
    vnode.style.right = "-80%";
    mask.style.right = "-100%";
  };
  if (data.open) {
    mask.style.transition = "all .2s";
    vnode.style.right = "0";
    mask.style.right = "0%";
  } else {
    mask.style.transition = "all .4s";
    mask.ontouchmove = () => null;
    vnode.ontouchmove = () => null;
    vnode.style.right = "-80%";
    mask.style.right = "-100%";
  }
};

const hSkip = () => {
  if (router.currentRoute.value.fullPath != "/eventDetail") return;
  router.push("/");
};
const hBack = () => {
  if (router.currentRoute.value.fullPath != "/eventDetail")
    return router.push("/eventDetail");
  router.push("/");
};

const handleJump = () => {
  window.open('https://saicdesignchallenge.com', '_blank')
}

defineExpose({ handle });
</script>

<style lang="less">
.layout-header-M {
  width: 100%;
  height: 44px;
  display: flex;
  padding: 0 0 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  > span {
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-right: 16px;
  }
  .image-wrapper {
    width: 130px;
    height: 40px;
  }
  .image-wrapper > img {
    width: 130px;
    height: 40px;
  }
  .back {
    display: flex;
    align-items: center;
    img {
      width: 9px;
      margin-right: 3px;
    }
  }
  .dialog-mask {
    position: fixed;
    top: 44px;
    right: -100%;
    width: 100%;
    height: calc(100% - 44px);
    background-color: rgba(#000000, 0.6);
  }

  .dialog-wrapper {
    width: 80%;
    height: calc(100% - 44px);
    position: fixed;
    top: 44px;
    right: -80%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    transition: all 0.4s;
  }

  .dialog-wrapper .nav-bar {
    width: 100%;
  }

  .dialog-wrapper a {
    width: 100%;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    color: #1d2129;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  .dialog-wrapper a .ant-switch {
    margin-bottom: 6px;
  }
  .dialog-wrapper a .ant-switch-checked {
    background-color: rgba(#000, 0.4);
  }

  .dialog-wrapper a.active {
    background: rgba(0, 0, 0, 0.08);
  }
  .dialog-wrapper a:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dialog-wrapper a >button {
    border: 0;
    outline: none;
    height: 24px;
    font-size: 12px;
    padding: 8px;
    border-radius: 52px;
    line-height: 20px;
    cursor: pointer;
    color: #ffffff;
    background: #FE3998;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-wrapper a >button:active {
    background: #CC196D;
  }
}
</style>
