<template>
  <div :class="locale === 'en' ? 'localeEn' : 'localeZh'">
    <header-phone
      :src="data.commonSrc && data.commonSrc[0]['extranetUrl']"
      :isGoBack="true"
    >
      <template v-slot:nav>
        <a @click="router.push('/')">{{ $t("HomePage") }}</a>
      </template></header-phone
    >
    <div class="content-box">
      <h2 class="top-title">{{ $t("DesignTask") }}</h2>
      <videoPC class="content-video" v-if="locale !== 'en'" :videoSrc="data.videoSrc" />
      <div v-if="locale !== 'en'" class="brief-introduction">
        <a-button
          class="button-type5 button"
          @click="hDownload"
          type="primary"
          :loading="data.isLoading"
        >{{ data.isLoading ? $t("Downloading") : $t("Download") }}</a-button
        >
      </div>
      <div v-if="locale !== 'en'" class="introduce-title">
        <div class="title1">面向真实世界的未来想象</div>
        <div class="title2">Imagining the Future For the Real World</div>

        <div class="title3">主赛道:汽车造型设计</div>
      </div>
      <div v-if="locale === 'en'" class="introduce-title">
        <div class="title4">12TH SAIC DESIGN CHALLENGE</div>
        <div class="title5">Electrifying Progress</div>
      </div>
      <div v-if="locale === 'en'" class="brief-introduction brief-introduction-bottom" style="margin-top: -30px">
        <a-button
          class="button-type5 button"
          @click="hDownload"
          type="primary"
          :loading="data.isLoading"
        >{{ data.isLoading ? $t("Downloading") : $t("Download") }}</a-button
        >
      </div>
    </div>
    <img style="width: 100%" :src="locale === 'en' ? data.backgroundImage1 : data.backgroundImage7" alt="" />
<!--    <div-->
<!--      class="advertising-img"-->
<!--      :style="{-->
<!--        backgroundImage: locale === 'en' ?-->
<!--          'url(' +-->
<!--          data.backgroundImage1 +-->
<!--          '),' +-->
<!--          'url(' +-->
<!--          data.backgroundImage1 +-->
<!--          ')' : 'url(' +-->
<!--          data.backgroundImage7 +-->
<!--          '),' +-->
<!--          'url(' +-->
<!--          data.backgroundImage7 +-->
<!--          ')',-->
<!--        backgroundSize: '100%',-->
<!--      }"-->
<!--    ></div>-->
    <div v-if="locale === 'zh'" class="submission-content">
      <h4>提交材料：</h4>
      <div class="item-box">
        <p><span class="item-bold">1、个人介绍：</span>100秒自我介绍MP4视频*1，简历pdf*1。</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">2、两张展示板：</span>A3幅面大小（420×297mm）、JPEG格式且分辨率不低于300DPI，排版形式横向排版；内容中必须包含：草图展示，方案前后45度3D渲染效果图各一张，正视图及侧视图的车身布局（Package）一张。</p>
      </div>
      <div class="item-box">
        <p>*设计作品需体现品牌（品牌选择：荣威，名爵，飞凡）</p>
      </div>
      <div class="item-box">
        <p>*作品尺寸需满足以下条件：轴距不得小于2600mm， 不得大于3000mm；车身长度不得小于3800mm，不得大于5000mm（如下图示意）。</p>
      </div>
      <img class="car-img" :src="data.backgroundImage2" alt="" @click="showFn(data.backgroundImage2)" />
      <div class="item-box">
        <p><span class="item-bold">3、设计作品的3D数字模型文件：</span>可接受的软件格式包括但不限于：Alias、Pro-E、UG、Catia、 Maya、 3D Max、 Rhino、Blender。</p>
      </div>
      <h4>选交材料：</h4>
      <div class="item-box">
        <p><span class="item-bold">1、个人作品集</span></p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">2、更多展示板：</span>设计意象图、车身布局、概念草图、人机工程、细节设计、文字说明等内容。</p>
      </div>
    </div>
    <div v-if="locale === 'en'" class="submission-content">
      <h4>Submit contents:
      </h4>
      <div class="item-box">
        <p><span class="item-bold">1-Intention:</span> Brief explanation story.</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">2-Moodboard:</span> supporting the story.</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">3-3 views of the car:</span> Hand sketches + renders.</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">4-Quality requested:</span> A3, 72dpi.</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">5-New design,</span> never seen before.</p>
      </div>
      <div class="item-box">
        <p>*The dimensions must meet the following criteria: wheelbase no less than 2600mm and no greater than 3000mm; body length no less than 3800mm and no greater than 5000mm.</p>
      </div>
      <img class="car-img" :src="data.backgroundImage2" alt="" @click="showFn(data.backgroundImage2)" />
      <p class="item-liner">Sub-track</p>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title1">
        <div class="title4">Brand Strategy and Service Design Sub-track</div>
        <div class="title5">Cities and Cars</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title1">
        <div class="title1">城市与车</div>
        <div class="title2">City and mobility</div>
        <div class="title3">分赛道1:品牌战略与服务设计</div>
      </div>
      <p class="submission-title">{{ $t("CompetitionText99") }}</p>
      <h4>{{ $t("CompetitionText100") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText101") }}</p>
      </div>
      <h4>{{ $t("CompetitionText102") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText103") }}</span><span>{{ $t("CompetitionText104") }}</span></p>
        <p class="item-bold">{{ $t("CompetitionText105") }}</p>
        <p class="item-bold">{{ $t("CompetitionText106") }}</p>
        <p class="item-bold">{{ $t("CompetitionText107") }}</p>
        <p class="item-bold">{{ $t("CompetitionText108") }}</p>
        <p class="item-bold">{{ $t("CompetitionText109") }}</p>
      </div>
      <h4>{{ $t("CompetitionText110") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText111") }}</p>
      </div>
    </div>

    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title2">
        <div class="title4">Sub-track: Car digital visualization design</div>
        <div class="title5">Hyper Imagination</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title2">
        <div class="title1">超级想象力</div>
        <div class="title2">Hyper Imagination</div>
        <div class="title3">分赛道2:汽车数字可视化设计分赛道</div>
      </div>
      <p class="submission-title">{{ $t("CompetitionText112") }}</p>
      <h4>{{ $t("CompetitionText113") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText114") }}</p>
      </div>
      <h4>{{ $t("CompetitionText115") }}</h4>
      <div class="ul-item-box">
        <p v-if="locale === 'en'">Entry submission</p>
        <p><span class="item-bold">{{ $t("CompetitionText116") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText117") }}</span><span>{{ $t("CompetitionText118") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText119") }}</span><span>{{ $t("CompetitionText120") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText121") }}</span><span>{{ $t("CompetitionText122") }}</span></p>
      </div>
      <h4>{{ $t("CompetitionText123") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText124") }}</span><span>{{ $t("CompetitionText125") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText126") }}</span><span>{{ $t("CompetitionText127") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText128") }}</span><span>{{ $t("CompetitionText129") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText130") }}</span><span>{{ $t("CompetitionText131") }}</span></p>
      </div>
      <h4>{{ $t("CompetitionText132") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText133") }}</span><span>{{ $t("CompetitionText134") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText135") }}</span><span>{{ $t("CompetitionText136") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText137") }}</span><span>{{ $t("CompetitionText138") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText139") }}</span><span>{{ $t("CompetitionText140") }}</span></p>
      </div>
    </div>

    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title3">
        <div class="title4">Design Strategy and Communication Sub-track</div>
        <div class="title5">Needs and Minds</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title3">
        <div class="title1">需求与心智</div>
        <div class="title2">Needs and minds</div>
        <div class="title3">分赛道3:设计策略与传达分赛道</div>
      </div>
      <p class="submission-title">{{ $t("CompetitionText141") }}</p>
      <div class="submission-title ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText142") }}</span><span>{{ $t("CompetitionText143") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText144") }}</span><span>{{ $t("CompetitionText145") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText146") }}</span><span>{{ $t("CompetitionText147") }}</span></p>
      </div>
      <h4>{{ $t("CompetitionText148") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText149") }}</p>
      </div>
      <h4>{{ $t("CompetitionText150") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText151") }}</span><span>{{ $t("CompetitionText152") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText153") }}</span><span>{{ $t("CompetitionText154") }}</span></p>
      </div>
    </div>

    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title4">
        <div class="title4">HMI Interaction Design Sub-track</div>
        <div class="title5">A 「WOW」smart cabin</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title4">
        <div class="title1">一个「WOW」的智舱</div>
        <div class="title2">A 「WOW」smart cabin</div>
        <div class="title3">分赛道4:HMI交互设计</div>
      </div>
      <p class="submission-title">{{ $t("CompetitionText155") }}</p>
      <h4>{{ $t("CompetitionText156") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText157") }}</p>
      </div>
      <h4>{{ $t("CompetitionText158") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText159") }}</span><span>{{ $t("CompetitionText160") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText161") }}</span><span>{{ $t("CompetitionText162") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText163") }}</span><span>{{ $t("CompetitionText164") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText165") }}</span><span>{{ $t("CompetitionText166") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText167") }}</span><span>{{ $t("CompetitionText168") }}</span></p>
      </div>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title5">
        <div class="title4">CMF Sub-track</div>
        <div class="title5">Beyond Colors</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title5">
        <div class="title1">不止是色彩</div>
        <div class="title2">Beyond Colors</div>
        <div class="title3">分赛道5:CMF设计</div>
      </div>
      <p class="submission-title">{{ $t("CompetitionText169") }}</p>
      <h4>{{ $t("CompetitionText170") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText171") }}</p>
      </div>
      <h4>{{ $t("CompetitionText172") }}</h4>
      <div class="ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText173") }}</span><span>{{ $t("CompetitionText174") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText175") }}</span><span>{{ $t("CompetitionText176") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText177") }}</span><span>{{ $t("CompetitionText178") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText179") }}</span><span>{{ $t("CompetitionText180") }}</span></p>
      </div>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title6">
        <div class="title4">Expert Jury</div>
        <div class="title5">专家评审团</div>
        <div class="title6">*Sort by the first letter of the surname</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title6">
        <div class="title1">专家评审团</div>
        <div class="title3">Expert Jury</div>
        <div class="title6">*按姓氏首字母排列</div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center; align-items: center">
        <img style="width: 33%;margin-bottom: 24px;" :src="data.backgroundImage5" alt="" />
      </div>
      <img style="width: 100%;margin-bottom: 30px;" :src="data.backgroundImage6" alt="" />
      <img style="width: 100%" :src="data.backgroundImage3" alt="" />
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title7">
        <div class="title4">Time Arrangement (Provisional)</div>
        <div class="title5">赛程时间（暂定）</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title7">
        <div class="title1">赛程时间（暂定）</div>
        <div class="title2">Time Arrangement (Provisional)</div>
      </div>
      <img style="width: 100%" :src="data.backgroundImage4" alt="" />
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title8">
        <div class="title4">Submission Approach</div>
        <div class="title5">大赛作品提交方式</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title8">
        <div class="title1">大赛作品提交方式</div>
        <div class="title3">Submission Approach</div>
      </div>
      <div class="submission-title ul-item-box">
        <p><span class="item-bold">{{ $t("CompetitionText181") }}</span><span>{{ $t("CompetitionText182") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText183") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText184") }}</span><span>{{ $t("CompetitionText185") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText186") }}</span></p>
        <p><span class="item-bold">{{ $t("CompetitionText187") }}</span><span>{{ $t("CompetitionText188") }}</span></p>
      </div>
      <p v-if="locale === 'en'" class="submission-title">Mail：saicdesign@saicmotor.com</p>
    </div>
    <nut-imagepreview
      :show="data.showPreview"
      :images="data.images"
      @close="data.showPreview = false"
    />
  </div>
</template>

<script setup>
import videoPC from "@/components/video-PC.vue";
import API from "../api/index.js";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { reactive, onBeforeMount, onMounted, ref } from "vue";
import headerPhone from "@/components/header-phone.vue";
import { useI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler'
const { t, locale } = useI18n();

const router = useRouter();
const route = useRoute();
const data = reactive({
  imgList: null,
  showPreview: false,
  images: [{ src: "" }],
  commonSrc: null,
  videoSrc: "",
  isLoading: false,
  backgroundImage1: "",
  backgroundImage2: "",
  backgroundImage3: "",
  backgroundImage4: "",
  backgroundImage5: "",
  backgroundImage6: "",
  backgroundImage7: "",
});

// const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));
data.videoSrc = JSON.parse(route.query.videoSrc);
const getImage = async () => {
  const res = await API.requestImage({
    fileDirUrl: "SAIC_Design/prod/other/",
  }).catch((e) => e);
  data.imgList = res.data;
};

const getOther = async () => {
  const res = await API.requestImage({
    fileDirUrl: "SAIC_Design/prod/other/",
  }).catch((e) => e);
  data.resOther = res.data;
  data.backgroundImage1 = data.resOther[24]["extranetUrl"];
  data.backgroundImage2 = data.resOther[31]["extranetUrl"];
  data.backgroundImage3 = data.resOther[29]["extranetUrl"];
  data.backgroundImage4 = data.resOther[27]["extranetUrl"];
  data.backgroundImage5 = data.resOther[34]["extranetUrl"];
  data.backgroundImage6 = data.resOther[35]["extranetUrl"];
  data.backgroundImage7 = data.resOther[26]["extranetUrl"];
};

const showFn = (src) => {
  data.images[0].src = src;
  data.showPreview = true;
};

const fetchComonImage = () => {
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).then((res) => {
    data.commonSrc = res.data;
  });
};

// 下载事件
const hDownload = () => {
  data.isLoading = true;
  let reqInstance = axios.create({
    // params: { apikey: 'bh42rxK05xklU6gs3IFSVYmR007IOQ1c' },
    headers: { loginName: "123" },
  });
  const config = {
    responseType: "blob", //这个一定要设置，否则会出现文件下载后打不开的情况
  };
  reqInstance
    .post(
      // "https://api-at.saicmotortest.com/vpdp/mobile/dev/v1/design/oss/file/oss/download",
      "/design/oss/file/oss/download",
      {
        ossFilePath: "SAIC_Design/prod/document/各赛道参考案例及赛事logo.zip",
      },
      config
    )
    .then((res) => {
      // const blob = new Blob(res, {
      const blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      let objectUrl = URL.createObjectURL(blob); //创建下载的链接
      let a = document.createElement("a");
      a.href = objectUrl;
      a.download = "各赛道参考案例及赛事logo.zip"; //设置文件名
      // a.download = "赛事资料.xlsx"; //设置文件名
      //下面这个写法兼容火狐
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      window.URL.revokeObjectURL(blob); //释放bolb对象
    })
    .then((e) => {
      data.isLoading = false;
    })
    .catch(err => {
      data.isLoading = false;
    })
};

onBeforeMount(() => {
  getOther();
  fetchComonImage();
});
</script>

<style lang="less" scoped>
@comMargin: 48px auto;
@comPadding: 0 7%;
@buttonColor: #FB3995;
@subButtonColor: #13D5BE;
@media screen and (max-width: 750px) {
  .layout-header-M {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    padding: 0 24px;
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    // transition: all 0.5s;
    .goBack {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .content-box {
    .top-title,
    .brief-introduction {
      padding: 0 24px;
    }
    .content-video {
      width: 100%;
      height: 210px;
    }
    .top-title {
      margin: 64px 0 20px 0 !important;
      font-weight: 700;
      font-size: 24px;
    }
    .brief-introduction-bottom {
      margin-bottom: 60px;
    }
    .brief-introduction {
      margin-top: 20px;
      h2 {
        font-size: 32px;
      }
      p {
        text-align: center;
      }
      button {
        // position: absolute;
        // top: 70%;
        margin-top: 24px !important;
        min-width: 0;
      }
      // .download-box {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   border: 1px solid #000;
      //   border-radius: 3px;
      //   color: #000;
      //   width: 80px;
      //   height: 25px;
      //   margin-top: 20px;
      //   padding: 4px 8px;
      // }
    }
  }
  .advertising-img {
    margin-top: 20px;
    padding: 105px 0;
    width: 100%;
    height: 1955px;
    background: repeat-x 0px 0px/100% 100%, repeat-x 0px 0px/100% 100%;
  }
  .submission-content {
    padding: 24px 18px;
    h2 {
      font-size: 24px;
      margin: 10px 0 !important;
      text-align: center;
    }
    h4 {
      font-size: 20px;
      margin: 30px 0 30px 0 !important;
      font-weight: 700;
    }
    .item-box {
      margin-bottom: 16px;
      h5 {
        font-weight: 700;
        margin-bottom: 5px !important;
      }
      p {
        line-height: 24px;
      }
    }
    .ul-item-box {
      h5 {
        font-weight: 600;
        margin-bottom: 5px !important;
      }
      p {
        line-height: 24px;
      }
    }
    .dl-item-box {
      display: flex;
      // align-items: center;
      margin-bottom: 15px;
      font-size: 12px;
      .index {
        display: block;
        margin-top: 1px;
        font-weight: 600;
        white-space: nowrap;
      }
      p {
        line-height: 24px;
        span {
          font-weight: 600;
        }
      }
    }
    .en-title {
      // position: relative;
      width: 100%;
      height: 20px;
      margin: 20px auto 0;
      background: #c5f946;
      text-align: center;
      span {
        display: block;
        transform: translateY(-50%);
        font-size: 16px;
      }
    }
    .tips {
      font-weight: 700;
      display: block;
      margin-top: 24px;
    }
  }
  .submission-title {
    color: #FB3995;
    line-height: 24px;
  }
  .warn {
    color: #ee0000;
    margin-right: 3px;
  }
  h5 {
    font-size: 16px;
  }
  p,
  span,
  button {
    font-size: 14px;
    line-height: 32px;
  }
  /deep/ .nut-imagepreview-box {
    img {
      display: block;
      margin: 20px auto 0;
      width: 85%;
    }
  }
  /deep/.ant-timeline {
    margin-top: 49px;
    .ant-timeline-item-head-blue {
      border-color: #71ad49;
      background: #71ad49;
    }
    .ant-timeline-item-tail {
      border-left: 2px solid #71ad49;
    }
  }
}
.car-img {
  width: 100%;
  margin-bottom: 10px;
}
.item-bold {
  font-weight: bold;
}
.item-liner {
  width: 100%;
  height: 41px;
  line-height: 41px;
  background-color: #FB3995;
  color: #FFFFFF;
  margin: 60px 0 0 0;
  text-align: center;
  font-size: 32px;
}
.button-type5 {
  width: 163px;
  height: 40px;
  line-height: 20px;
  border-radius: 20px;
  background-color: #FE3998 !important;
}
.button-type5:hover {
  background-color: #CC196D !important;
}
.button-type5:active {
  background-color: #CC196D !important;
}
.introduce-title {
  width: 355px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 328px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 355px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 355px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 316px;
    background-color: @buttonColor;
    font-size: 18px;
    font-weight: bold;
  }
  .title5 {
    width: 251px;
    background-color: @subButtonColor;
    font-size: 20px;
    font-weight: bold;
  }
}
.introduce-title1 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 164px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 313px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 313px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 335px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 279px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title2 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 192px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 335px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 335px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 335px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 312px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title3 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 192px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 305px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 305px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 335px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 316px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title4 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 309px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 248px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 248px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 335px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 314px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title5 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 192px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 178px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 178px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 179px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 274px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title6 {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 43.3%;
    background-color: @buttonColor;
    font-size: 24px;
  }
  .title2 {
    width: 43.3%;
    background-color: @subButtonColor;
    font-size: 18px;
  }
  .title3 {
    width: 43.3%;
    background-color: @subButtonColor;
    font-size: 18px;
  }
  .title4 {
    width: 36%;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 43.3%;
    background-color: @subButtonColor;
    font-size: 24px;
  }
  .title6 {
    width: 100%%;
    color: #FF3A96;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
  }
}
.introduce-title7 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 333px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 333px;
    background-color: @subButtonColor;
    font-size: 18px;
  }
  .title3 {
    width: 333px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 333px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 264px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.introduce-title8 {
  width: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #FFFFFF;
  .title1 {
    width: 274px;
    background-color: @buttonColor;
    font-size: 28px;
  }
  .title2 {
    width: 274px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title3 {
    width: 274px;
    background-color: @subButtonColor;
    font-size: 20px;
  }
  .title4 {
    width: 243px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    width: 274px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.localeEn {
  .submission-content > div:nth-child(1) {
    font-weight: bold;
  }
  .submission-content > div:nth-child(2) {
    font-weight: bold;
  }
  div, p, span {
    font-family: 'Aalibaba PuHui-A';
  }
}
.localeZh {
  div, p, span {
    font-family: 'AalibabaPuHui-A';
  }
}
</style>
