<template>
  <div class="video-right">
    <video
      controls
      @pause="videoPause"
      @play="videoPlay"
      ref="video"
      :src="props.videoSrc"
      @loadeddata="setVideoPoster($event)"
    ></video>
    <div
      v-if="!data.isMobile && data.videoPauseShow"
      class="prism-big-play-btn"
      @click="videoPlay"
    >
      <img src="@/assets/Vector.png" alt="" />
      <div class="outter"></div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  ref,
  computed,
  nextTick,
} from "vue";

const props = defineProps({
  videoSrc: {
    type: String,
    default:
      "http://oss-qa-sh-vpdp3-tids-pri.oss-cn-shanghai-internal.aliyuncs.com/saic-design/index/%E6%88%91%E6%98%AF%E6%B1%BD%E8%BD%A6%E8%AE%BE%E8%AE%A1%E5%B8%88-16-9%E5%A4%96%E5%8F%91%E7%89%88%E6%9C%AC%E9%BB%91%E5%BA%95%E7%AC%91%E8%84%B8%E5%A2%99.m4v?OSSAccessKeyId=LTAI5tDxN1gGqFbG1urth5qx&Expires=360000001678775600&Signature=orHOm5JvXmzHPFR3RZnaOcgdzdk%3D",
  },
});
const data = reactive({
  videoPauseShow: true,
  isMobile: Boolean,
});
const video = ref();
const videoPlay = () => {
  // 点击盒子让播放按钮隐藏
  data.videoPauseShow = false;
  video.value.play();
};
const videoPause = () => {
  data.videoPauseShow = true;
};
const setVideoPoster = (e) => {
  let video = e.target;
  if (video.src.indexOf('v5-2024.mp4') > -1) {
    video.poster = require('../assets/video4.jpg')
  } else {
    // video.height = video.clientHeight;
    let canvas = document.createElement("canvas"); // 创建 canvas
    const ctx = canvas.getContext("2d");
    video.currentTime = 1; // 第一帧
    video.oncanplay = () => {
      canvas.width = video.clientWidth; // 获取视频宽度
      canvas.height = video.clientHeight; //获取视频高度
      let img = new Image(); // 这里使用img是为了解决视频跨域 canvas.toDataURL会提示错误的问题
      img.onload = function () {
        // canvas绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式并设置封面
        video.poster = canvas.toDataURL("image/jpeg", 1); // 截取后的视频封面
      }
    }
  }
}
onMounted(() => {
  data.isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
});
</script>

<style lang="less" scoped>
.video-right {
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .prism-big-play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: rgba(0, 0, 0, 0.5);
    background-size: contain;
    cursor: pointer;
    img {
      position: absolute;
      left: 54%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 20px;
    }
    .outter {
      border: 1px solid rgba(255, 255, 255, 0.2);
      width: 64px;
      height: 64px;
      border-radius: 100%;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
    }

    // .outter:hover {
    //     content: "";
    //     width: 80px;
    //     height: 80px;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     margin-left: -40px;
    //     margin-top: -40px;
    //     border-radius: 50%;
    //     //a1为经过@keyframes 规则建立的动画名称，可本身定义web
    //     // a2 为动画时常 ，例如：1s   2s   可自行规定浏览器
    //     // a3 为css自带的动画效果，有以下几个：
    //     // 类名称+持续时间+（变化方式,延迟时间，重复次数（infinite循环），重复方式，开始结束的状态）
    //     animation: warn 1s ease-out 0s infinite;
    // }

    // 在warn方法里,用百分比来规定变化发生的时间，或用关键词 "from" 和 "to"，等同于 0% 和 100%。
    // 0% 是动画的开始，100% 是动画的完成，建议使用百分比，会更加详细：
    // @keyframes warn {
    //     0% {
    //         // transform: scale表示缩放，值表示倍数，可以指定X/Y轴
    //         transform: scale(0.5);
    //         opacity: 1;
    //     }

    //     30% {
    //         opacity: 1;
    //     }

    //     100% {
    //         transform: scale(1.4);
    //         opacity: 0;
    //     }
    // }
  }
}
</style>
