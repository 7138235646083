import axios from 'axios'
import i18n from '@/locals/index'
import { Modal, message } from 'ant-design-vue';
const instance = axios.create({
    // baseURL: 'https://api-at.saicmotortest.com/vpdp/mobile/dev/v1/',
    // params: { apikey: 'bh42rxK05xklU6gs3IFSVYmR007IOQ1c' },
    baseURL: '/',
    timeout: 60000,
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response.data;
}, function (error) {

    Modal.destroyAll()
    if (error.message.includes('timeout') || (error.state == 500 && error.code == -1)) {
        Modal.error({
            title: () => i18n.global.t('Error'),
            content: () => i18n.global.t('timeout'),
        });
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance
