import {
    createRouter,
    createWebHashHistory
} from 'vue-router'
import Home from '../views/index.vue'
import eventDetail from '@/views/eventDetail.vue'
import worksDetail from '@/views/worksDetailPhone.vue'
import designTask from '@/views/designTask.vue'
import {onMounted, ref} from "vue";
import upload from "@/views/upload";
const isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
let routes = [{
    path: '/',
    name: 'index',
    redirect: '/index'
}, {
    path: '/index',
    name: 'home',
    component: Home
},
{
    path: '/eventDetail',
    name: 'eventDetail',
    component: eventDetail
},
{
    path: '/worksDetail',
    name: 'worksDetail',
    component: worksDetail
},
{
    path: '/designTask',
    name: 'designTask',
    component: designTask
}]
if (!isMobile) {
    routes.push({
        path: '/upload',
        name: 'upload',
        component: upload
    })
}
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        return { top:0 }
    }
})

export default router
