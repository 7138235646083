<template>
  <div class="containner">
    <!--PC导航-->
    <header-pc
      v-if="!mobile"
      :src="data.commonSrc && data.commonSrc[0].extranetUrl"
      :data="data.navData"
      :fixed="data.fixed"
      :language="locale"
      :active="data.activeNav"
    ></header-pc>

    <!--Mobile导航-->
    <header-phone
      v-if="mobile"
      ref="header"
      :src="data.commonSrc && data.commonSrc[0].extranetUrl"
      :data="data.navData"
      :language="locale"
    >
      <template v-slot:nav>
        <a
          :class="{ active: data.activeNav === index }"
          href="javascript:;"
          v-for="(item, index) in data.navData"
          :key="item.id"
          @click="handleJump(item.id, index)"
          >{{ $t(item.value) }}</a
        >
        <a @click="pageChange()">{{ $t("SDCMegagame") }}</a>
      </template>
    </header-phone>

    <!-- 视频轮播 -->
    <swiper
      :loop="true"
      :modules="modules"
      :autoplay="false"
      :pagination="{ clickable: false }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide>
        <a-spin :spinning="!data.videoSrc" :indicator="indicator">
          <div v-if="videoLoading" class="loading">
            <img src="@/assets/loading.png" />
          </div>
          <video
            v-if="data.active == 0"
            :src="data.videoSrc && data.videoSrc[0].extranetUrl"
            :muted="!mobile"
            :autoplay="!mobile"
            :controls="mobile"
            poster="@/assets/video1.jpeg"
          ></video>
        </a-spin>
      </swiper-slide>
      <swiper-slide>
        <a-spin :spinning="!data.videoSrc" :indicator="indicator">
          <div v-if="videoLoading" class="loading">
            <img src="@/assets/loading.png" />
          </div>
          <video
            v-if="data.active == 1"
            :src="data.videoSrc.length && data.videoSrc[1].extranetUrl"
            :muted="!mobile"
            :autoplay="!mobile"
            :controls="mobile"
            poster="@/assets/video2.jpeg"
          ></video>
        </a-spin>
      </swiper-slide>
      <swiper-slide>
        <a-spin :spinning="!data.videoSrc" :indicator="indicator">
          <div v-if="videoLoading" class="loading">
            <img src="@/assets/loading.png" />
          </div>
          <video
            v-if="data.active == 2"
            :src="data.videoSrc.length && data.videoSrc[2].extranetUrl"
            :muted="!mobile"
            :autoplay="!mobile"
            :controls="mobile"
            poster="@/assets/video4.jpg"
          ></video>
        </a-spin>
      </swiper-slide>
    </swiper>

    <!-- 我们的故事 -->
    <div id="we-story" class="we-story">
      <div class="story-content">
        <p>{{ $t("OurStory") }}</p>
        <p v-html="$t('StoryDesc')"></p>
        <div class="QR-code">
          <a-tooltip color="#fff" placement="top">
            <template v-if="data.commonSrc" #title>
              <a-spin :spinning="!data.commonSrc" :indicator="indicator">
                <img
                  style="width: 116px; height: 116px"
                  :src="data.commonSrc && data.commonSrc[2].extranetUrl"
                />
              </a-spin>
            </template>
            <div class="code-item">
              <a-spin :spinning="!data.commonSrc" :indicator="indicator">
                <img :src="data.commonSrc && data.commonSrc[2].extranetUrl" />
              </a-spin>
              <span>{{ $t("OfficialWeibo") }}</span>
            </div>
          </a-tooltip>
          <a-tooltip color="#fff" placement="top">
            <template v-if="data.commonSrc" #title>
              <a-spin :spinning="!data.commonSrc" :indicator="indicator">
                <img
                  style="width: 116px; height: 116px"
                  :src="data.commonSrc && data.commonSrc[3].extranetUrl"
                />
              </a-spin>
            </template>
            <div class="code-item">
              <a-spin :spinning="!data.commonSrc" :indicator="indicator">
                <img :src="data.commonSrc && data.commonSrc[3].extranetUrl" />
              </a-spin>
              <span>{{ $t("OfficialWeChat") }}</span>
            </div>
          </a-tooltip>
        </div>
      </div>
      <div class="story-image">
        <a-spin :spinning="!data.teamSrc" :indicator="indicator">
          <img :src="data.teamSrc && data.teamSrc[0].extranetUrl" />
        </a-spin>
      </div>
    </div>

    <!-- 我们的团队 -->
    <div id="we-team" class="we-team">
      <div class="we-team-content">
        <div class="our-team">
          <p>{{ $t("OurTeam") }}</p>
          <p v-html="$t('TeamIntro')"></p>
        </div>
        <div class="team-image">
          <div class="image-wrapper">
            <a-spin :spinning="!data.teamSrc" :indicator="indicator">
              <img :src="data.teamSrc && data.teamSrc[2].extranetUrl" />
            </a-spin>
          </div>
          <div class="team-leader">
            <p>{{ $t("Leader") }} ｜ Design President</p>
            <p v-html="$t('NameIntro')"></p>
          </div>
        </div>
        <img
          v-if="data.teamSrc"
          :src="data.teamSrc && data.teamSrc[1].extranetUrl"
        />
        <div class="team-leader">
          <p>{{ $t("Leader") }} ｜ Design President</p>
          <p v-html="$t('NameIntro')"></p>
        </div>
      </div>
      <div class="team-wrapper">
        <div class="team-wrapper-row-1">
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[5].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name3") }}</p>
            <p>{{ $t("Position3") }}</p>
          </div>
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[9].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name7") }}</p>
            <p>{{ $t("Position7") }}</p>
          </div>
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[7].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name5") }}</p>
            <p>{{ $t("Position5") }}</p>
          </div>
          <div v-if="data.isMobile" class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[8].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name6") }}</p>
            <p>{{ $t("Position6") }}</p>
          </div>
        </div>
        <a-row class="team-wrapper-row-2">
          <div v-if="!data.isMobile" class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[8].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name6") }}</p>
            <p>{{ $t("Position6") }}</p>
          </div>
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[3].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name1") }}</p>
            <p>{{ $t("Position1") }}</p>
          </div>
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[6].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name4") }}</p>
            <p>{{ $t("Position4") }}</p>
          </div>
          <div class="team-item">
            <div class="item-image">
              <a-spin :spinning="!data.teamSrc" :indicator="indicator">
                <img :src="data.teamSrc && data.teamSrc[4].extranetUrl" alt />
              </a-spin>
            </div>
            <p>{{ $t("Name2") }}</p>
            <p>{{ $t("Position2") }}</p>
          </div>
        </a-row>
      </div>
    </div>

    <!-- 品牌介绍 -->
    <div id="brand-example" class="brand-example">
      <div class="brand-example-content">
        <div class="brand">
          <h1>{{ $t("Brand") }}</h1>
          <p v-html="$t('introduction')"></p>
        </div>
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[0].extranetUrl" alt />
          </a-spin>
        </div>
      </div>
    </div>

    <!-- 作品集 -->
    <div id="brand-images" class="brand-images">
      <div class="brand-wrapper">
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[23].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content">
          <div class="image-wrapper">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[25].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[24].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[24].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand1") }}</p>
              <p>{{ $t("brand1Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-right">
            <div class="introduce-desc">
              <p>{{ $t("brand2") }}</p>
              <p>{{ $t("brand2Desc") }}</p>
            </div>
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[28].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[28].extranetUrl" alt />
              </a-spin>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[26].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[26].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand3") }}</p>
              <p>{{ $t("brand3Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-right">
            <div class="introduce-desc">
              <p>{{ $t("brand4") }}</p>
              <p>{{ $t("brand4Desc") }}</p>
            </div>
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[27].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[27].extranetUrl" alt />
              </a-spin>
            </div>
          </div>
        </div>
      </div>
      <div class="brand-wrapper">
        <div class="image-wrapper" @click="handleDrawer(data.workSrc[17].extranetUrl, '')">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[17].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content">
          <div class="image-wrapper" @click="handleDrawer(data.workSrc[18].extranetUrl, '')">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[18].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[19].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[19].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand5") }}</p>
              <p>{{ $t("brand5Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-right">
            <div class="introduce-desc">
              <p>{{ $t("brand6") }}</p>
              <p>{{ $t("brand6Desc") }}</p>
            </div>
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[20].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[20].extranetUrl" alt />
              </a-spin>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[21].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[21].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand7") }}</p>
              <p>{{ $t("brand7Desc") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="brand-wrapper">
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[11].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content brand-content-f7">
          <div class="image-wrapper">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[12].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[13].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[13].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p style="margin-left: -4px;">{{ $t("brand8") }}</p>
              <p>{{ $t("brand8Desc1") }}</p>
              <p>{{ $t("brand8Desc2") }}</p>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-right">
            <div class="introduce-desc">
              <p>{{ $t("brand9") }}</p>
              <p>{{ $t("brand9Desc1") }}</p>
              <p>{{ $t("brand9Desc2") }}</p>
              <p>{{ $t("brand9Desc3") }}</p>
              <p>{{ $t("brand9Desc4") }}</p>
            </div>
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[14].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[14].extranetUrl" alt />
              </a-spin>
            </div>
          </div>
          <div class="brand-introduce brand-introduce-left">
            <div class="image-wrapper" @click="handleDrawer(data.workSrc[15].extranetUrl, '')">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[15].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand10") }}</p>
              <p>{{ $t("brand10Desc1") }}</p>
              <p>{{ $t("brand10Desc2") }}</p>
            </div>
          </div>
        </div>
      </div>
      <span>{{ $t("workEles") }}</span>
      <div class="brand-images-content">
        <div class="left-wrapper">
          <p>{{ $t("Desc") }}</p>
          <div class="image-item">
            <div
              class="image-wrapper"
              @click="handleDrawer(data.workSrc[2].extranetUrl, $t('work1'))"
            >
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[2].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work1") }}</p>
              <p>{{ $t("work1Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[4].extranetUrl, $t('work2'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[4].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work2") }}</p>
              <p>{{ $t("work2Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[6].extranetUrl, $t('work3'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[6].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work3") }}</p>
              <p>{{ $t("work3Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[8].extranetUrl, $t('work4'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[8].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work4") }}</p>
              <p>{{ $t("work4Desc") }}</p>
            </div>
          </div>
        </div>
        <div class="right-wrapper">
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[1].extranetUrl, $t('work5'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[1].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work5") }}</p>
              <p>{{ $t("work5Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[3].extranetUrl, $t('work6'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[3].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work6") }}</p>
              <p>{{ $t("work6Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[5].extranetUrl, $t('work7'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[5].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work7") }}</p>
              <p>{{ $t("work7Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[7].extranetUrl, $t('work8'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[7].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work8") }}</p>
              <p>{{ $t("work8Desc") }}</p>
            </div>
          </div>
          <div
            class="image-item"
            @click="handleDrawer(data.workSrc[9].extranetUrl, $t('work9'))"
          >
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[9].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="item-desc">
              <p>{{ $t("work9") }}</p>
              <p>{{ $t("work9Desc") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端作品集 -->
    <div id="brand-images-phone" class="brand-images-phone">
      <!-- <div v-if="data.isMobile" class="brand-info">{{ $t("brandInfo") }}</div> -->
      <div class="brand-wrapper">
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[22].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content">
          <div class="image-wrapper">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[25].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[24].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand1") }}</p>
              <p>{{ $t("brand1Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[28].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand2") }}</p>
              <p>{{ $t("brand2Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[26].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand3") }}</p>
              <p>{{ $t("brand3Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[27].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand4") }}</p>
              <p>{{ $t("brand4Desc") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="brand-wrapper">
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[16].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content">
          <div class="image-wrapper">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[18].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[19].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand5") }}</p>
              <p>{{ $t("brand5Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[20].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand6") }}</p>
              <p>{{ $t("brand6Desc") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[21].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand7") }}</p>
              <p>{{ $t("brand7Desc") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="brand-wrapper">
        <div class="image-wrapper">
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img :src="data.workSrc && data.workSrc[10].extranetUrl" alt />
          </a-spin>
        </div>
        <div class="brand-content brand-content-f7">
          <div class="image-wrapper">
            <a-spin :spinning="!data.workSrc" :indicator="indicator">
              <img :src="data.workSrc && data.workSrc[12].extranetUrl" alt />
            </a-spin>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[13].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p style="margin-left: -5px;">{{ $t("brand8") }}</p>
              <p>{{ $t("brand8Desc1") }}</p>
              <p>{{ $t("brand8Desc2") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[14].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand9") }}</p>
              <p>{{ $t("brand9Desc1") }}</p>
              <p>{{ $t("brand9Desc2") }}</p>
              <p>{{ $t("brand9Desc3") }}</p>
              <p>{{ $t("brand9Desc4") }}</p>
            </div>
          </div>
          <div class="brand-introduce">
            <div class="image-wrapper">
              <a-spin :spinning="!data.workSrc" :indicator="indicator">
                <img :src="data.workSrc && data.workSrc[15].extranetUrl" alt />
              </a-spin>
            </div>
            <div class="introduce-desc">
              <p>{{ $t("brand10") }}</p>
              <p>{{ $t("brand10Desc1") }}</p>
              <p>{{ $t("brand10Desc2") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[1].extranetUrl"
              @click="showFn(data.workSrc[1].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work5") }}</p>
          <p>{{ $t("work5Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[2].extranetUrl"
              @click="showFn(data.workSrc[2].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work1") }}</p>
          <p>{{ $t("work1Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[3].extranetUrl"
              @click="showFn(data.workSrc[3].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work6") }}</p>
          <p>{{ $t("work6Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[4].extranetUrl"
              @click="showFn(data.workSrc[4].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work2") }}</p>
          <p>{{ $t("work2Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc && data.workSrc[5].extranetUrl"
              @click="showFn(data.workSrc[5].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work7") }}</p>
          <p>{{ $t("work7Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[6].extranetUrl"
              @click="showFn(data.workSrc[6].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work3") }}</p>
          <p>{{ $t("work3Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[7].extranetUrl"
              @click="showFn(data.workSrc[7].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work8") }}</p>
          <p>{{ $t("work8Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[8].extranetUrl"
              @click="showFn(data.workSrc[8].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work4") }}</p>
          <p>{{ $t("work4Desc") }}</p>
        </div>
      </div>
      <div class="image-item">
        <div
          class="image-wrapper"
          :style="{ height: !data.workSrc ? '168px' : 'auto' }"
        >
          <a-spin :spinning="!data.workSrc" :indicator="indicator">
            <img
              :src="data.workSrc && data.workSrc[9].extranetUrl"
              @click="showFn(data.workSrc[9].extranetUrl)"
              alt
            />
          </a-spin>
        </div>
        <div class="item-desc">
          <p>{{ $t("work9") }}</p>
          <p>{{ $t("work9Desc") }}</p>
        </div>
      </div>
    </div>

    <!-- 大赛介绍 -->
    <div id="contest-intro" class="contest-intro">
      <div class="contest-intro-content">
        <h1>{{ $t("SDC") }}</h1>
        <p v-html="$t('ContestIntro')"></p>
      </div>
      <button class="button" @click="pageChange">{{ $t("Detail") }}</button>
      <div class="image-wrapper">
        <a-spin :spinning="!data.introSrc" :indicator="indicator">
          <img v-if="data.isMobile" :src="data.introSrc && data.introSrc[4].extranetUrl" />
          <img v-else :src="data.introSrc && data.introSrc[2].extranetUrl" />
          <img :src="data.introSrc && data.introSrc[3].extranetUrl" />
        </a-spin>
      </div>
    </div>
    <a-image
      v-if="data.current"
      :style="{ display: 'none' }"
      :preview="{ visible, onVisibleChange: handleDrawer }"
      :src="data.current"
      :alt="data.title"
    />
    <nut-imagepreview
      :show="showPreview"
      :images="data.images"
      @close="hideFn"
    />
    <footer-layout :src="data.commonSrc && data.commonSrc"></footer-layout>
  </div>
</template>

<script setup>
import API from "../api/index.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";

import { useRouter } from "vue-router";
import {
  ref,
  watch,
  inject,
  reactive,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";
import headerPc from "@/components/header-pc.vue";
import headerPhone from "@/components/header-phone.vue";
import footerLayout from "@/components/footer.vue";
import { useI18n } from "vue-i18n";

const modules = [Autoplay, Pagination, Navigation, Scrollbar];
const router = useRouter();
const { t, locale } = useI18n();

const mySwiper = ref();
const videoLoading = ref(true);
const visible = ref(false);
const mobile = ref(false);
const header = ref(null);
const showPreview = ref(false);
const indicator = inject("indicator");

const data = reactive({
  active: 0,
  title: null,
  current: null,
  fixed: false,
  commonSrc: null,
  teamSrc: null,
  workSrc: null,
  introSrc: null,
  videoSrc: null,
  activeNav: null,
  images: [{ src: "" }],
  navData: [
    { id: "#we-story", value: "AboutUs" },
    { id: "#we-team", value: "Team" },
    { id: "#brand-example", value: "Works" },
  ],
  isMobile: navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  ),
});
onMounted(() => {
  if (data.isMobile) {
    fetchVideoSrc();
    fetchComonImage();
    fetchTeamImage();
    fetchWorkImage();
    fetchIntroImage();
  } else {
    fetchVideoSrc();
  }
  window.scrollTo(0, 0);
  window.innerWidth > 750 && (mobile.value = false);
  window.innerWidth <= 750 && (mobile.value = true);
  window.addEventListener(
    "resize",
    () => {
      window.innerWidth > 750 && (mobile.value = false);
      window.innerWidth <= 750 && (mobile.value = true);
    },
    false
  );
  window.addEventListener("scroll", handleScroll, false);
  let vnodes = document.querySelectorAll(".swiper-pagination-bullet");
  vnodes.forEach((item, index) => {
    item.onclick = () => {
      data.active = index;
      mySwiper.value.slideTo(index, 0, false);
    };
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll, false);
  window.removeEventListener("resize", () => {}, false);
});

// 图片预览显示
const showFn = (src) => {
  data.images[0].src = src;
  showPreview.value = true;
};

// 图片预览隐藏
const hideFn = () => {
  showPreview.value = false;
};

// 跳转大赛页面
const pageChange = () => {
  mobile.value && header.value.handle();
  router.push("/eventDetail");
};

// 移动端导航
const handleJump = (name, index) => {
  header.value.handle();
  data.activeNav = index;
  let node = document.querySelector(name);
  let mask = document.querySelector(".dialog-mask");
  let vnode = document.querySelector(".dialog-wrapper");
  mask.style.transition = "all .4s";
  vnode.style.right = "-80%";
  mask.style.right = "-100%";
  window.scrollTo({ top: node.offsetTop, left: 0, behavior: "smooth" });
};

// 抽屉
const handleDrawer = (src, title) => {
  nextTick(() => {
    data.current = src;
    data.title = title;
    visible.value = true;
  });
};

// swiper初始化
const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

// swiper切换
const onSlideChange = (val) => {
  videoLoading.value = true;
  data.active = val.realIndex;
  nextTick(() => {
    let vnode = document.querySelector("video");
    vnode.removeEventListener("ended", () => {}, false);
    vnode.removeEventListener("canplay", () => {}, false);
    vnode.addEventListener("ended", () => {
      let index = data.active + 1 > 2 ? 0 : data.active + 1;
      mySwiper.value.slideTo(index, 0, false);
    });
    data.isMobile && (videoLoading.value = false);
    if (!data.isMobile) {
      vnode.addEventListener("canplay", () => {
        fetchComonImage();
        fetchTeamImage();
        fetchWorkImage();
        fetchIntroImage();
        videoLoading.value = false;
      });
    }
  });
};

// 吸顶操作
const handleScroll = () => {
  window.clearTimeout(data.timer);
  let vnode = document.querySelector(".layout-header-pc");
  let scropTop = document.documentElement.scrollTop || document.body.scrollTop;
  data.activeNav = null;
  scropTop >= document.querySelector("#we-story").offsetTop &&
    (data.activeNav = 0);
  scropTop >= document.querySelector("#we-team").offsetTop &&
    (data.activeNav = 1);
  scropTop >= document.querySelector("#brand-example").offsetTop &&
    (data.activeNav = 2);
  if (mobile.value) return;
  if (!scropTop) {
    vnode.className = "layout-header-pc";
    return;
  }
  if (scropTop <= window.innerHeight) {
    vnode.style.background = "transparent";
    vnode.style.boxShadow = "none";
  } else {
    vnode.style.background = "#FFFFFF";
    vnode.style.boxShadow = "0px 3px 5px rgba(0,0,0,0.2)";
  }
  vnode.className = "layout-header-pc up";
  data.timer = setTimeout(() => {
    vnode.className = "layout-header-pc down";
  }, 200);
};

// 视频接口
const fetchVideoSrc = () => {
  videoLoading.value = true;
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/",
  }).then((res) => {
    data.videoSrc = res.data;
    nextTick(() => {
      let vnode = document.querySelector("video");
      vnode.removeEventListener("ended", () => {}, false);
      vnode.removeEventListener("canplay", () => {}, false);
      vnode.addEventListener("ended", () => {
        let index = data.active + 1 > 2 ? 0 : data.active + 1;
        mySwiper.value.slideTo(index, 0, false);
      });
      data.isMobile && (videoLoading.value = false);
      if (!data.isMobile) {
        vnode.addEventListener("canplay", () => {
          fetchComonImage();
          fetchTeamImage();
          fetchWorkImage();
          fetchIntroImage();
          videoLoading.value = false;
        });
      }
    });
  });
};

// 公共图片
const fetchComonImage = () => {
  if (data.commonSrc) return;
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).then((res) => {
    data.commonSrc = res.data;
  });
};

// 我们的团队
const fetchTeamImage = () => {
  if (data.teamSrc) return;
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/team/",
  }).then((res) => {
    data.teamSrc = res.data;
  });
};

// 作品
const fetchWorkImage = () => {
  if (data.workSrc) return;
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/work/",
  }).then((res) => {
    data.workSrc = res.data;
  });
};

// 大赛介绍
const fetchIntroImage = () => {
  if (data.introSrc) return;
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/introduce/",
  }).then((res) => {
    data.introSrc = res.data;
  });
};
</script>

<style scoped lang="less">
@keyframes amplify {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes reduce {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes inprogress {
  0% {
    width: 0px;
  }
  100% {
    width: 40px;
  }
}

// PC
@media screen and (min-width: 751px) {
  .we-story,
  .we-team .we-team-content,
  .we-team .team-wrapper,
  .brand-example .brand-example-content,
  .brand-wrapper .brand-content,
  .brand-images .brand-images-content,
  .contest-intro .contest-intro-content {
    width: 1280px;
    margin: 0 auto;
  }
  .containner {
    width: 100%;
    height: auto;
    background-color: #ffffff;
  }

  .layout-header-M {
    display: none !important;
  }

  .swiper {
    width: 100%;
    height: 100vh;
    position: relative;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
    }

    .swiper-wrapper .swiper-slide {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #dfdfdf;
      overflow: hidden;
    }
    .swiper-wrapper .swiper-slide .loading {
      width: 100%;
      height: 100%;
      background-color: #dfdfdf;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-wrapper .swiper-slide .loading img {
      width: 320px;
      height: auto;
      opacity: 0.2;
      animation: loading 1.2s ease-in infinite forwards;
    }
    .swiper-wrapper .swiper-slide:deep(img) {
      width: 300px;
      height: auto;
    }
    .swiper-wrapper .swiper-slide video {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
    &:deep(.swiper-pagination) {
      width: auto;
      height: 4px;
      position: absolute;
      left: 120px;
      bottom: 54px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    &:deep(.swiper-pagination) span {
      width: 40px;
      height: 2px;
      opacity: 0.5;
      border-radius: 0;
      margin-right: 8px;
      background-color: #ffffff;
      box-sizing: content-box;
      position: relative;
      cursor: pointer;
    }
    &:deep(.swiper-pagination) span:after {
      content: "";
      width: 100%;
      height: 100%;
      padding: 29px 10px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      cursor: pointer;
    }
    &:deep(.swiper-pagination) span.swiper-pagination-bullet-active {
      width: 40px;
      height: 2px;
      opacity: 1;
      border-radius: 0;
      background-color: #ffffff;
    }
  }
  .we-story {
    height: 900px;
    padding: 270px 0;
    display: flex;
    flex-direction: row;

    .story-content {
      flex: 4.3;
      padding: 20px 120px 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      > p:nth-child(1) {
        font-size: 32px;
        line-height: 45px;
        padding-bottom: 24px;
        font-weight: bold;
      }

      > p:nth-child(2),
      > p:nth-child(3) {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 24px;
      }

      .QR-code {
        display: flex;
        flex-direction: row;

        .code-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          overflow: hidden;
          text-align: center;
        }

        .code-item:hover img {
          border: 1px solid;
        }

        .code-item img {
          width: 64px;
          height: 64px;
          padding: 4px;
          margin-bottom: 4px;
          border: 1px solid #e2e2e2;
        }

        .code-item span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .story-image {
      flex: 5.7;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        animation: reduce ease 0.5s forwards;
      }

      &:hover img {
        animation: amplify ease 0.5s forwards;
      }
    }
  }

  .we-team {
    width: 100%;
    height: auto;
    padding: 80px 0;
    background-color: #f0f0f0;
    .we-team-content > img,
    .we-team-content .team-leader {
      display: none;
    }
    .we-team-content .our-team {
      padding: 0 190px;
      margin-bottom: 80px;
    }
    .we-team-content .our-team p:first-child {
      font-size: 32px;
      text-align: center;
      padding-bottom: 24px;
      font-weight: bold;
    }
    .we-team-content .our-team p:last-child {
      font-size: 14px;
    }
    .we-team-content .team-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 80px;
      .image-wrapper {
        width: 480px;
        height: 620px;
        overflow: hidden;
      }
      .image-wrapper img {
        width: 480px;
        height: 634px;
        animation: reduce ease 0.5s forwards;
      }

      .image-wrapper:hover img {
        animation: amplify ease 0.5s forwards;
      }
      .team-leader {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 90px 0 90px 120px;
      }
      .team-leader p:first-child {
        font-size: 18px;
        padding-bottom: 12px;
        font-weight: bold;
      }
      .team-leader p:last-child {
        font-size: 14px;
        opacity: 0.6;
      }
    }
    .team-wrapper .team-wrapper-row-1,
    .team-wrapper .team-wrapper-row-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .team-wrapper .team-wrapper-row-1 .team-item {
      width: 374px;
      height: 284px;
      margin-bottom: 24px;
    }
    .team-wrapper .team-wrapper-row-2 .team-item {
      width: 270px;
      height: auto;
      margin-bottom: 24px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
    }
    .team-wrapper .team-wrapper-row-1 .team-item .item-image {
      width: 100%;
      min-height: 216px;
      overflow: hidden;
    }
    .team-wrapper .team-wrapper-row-2 .team-item .item-image {
      width: 130%;
      height: 216px;
      overflow: hidden;
    }
    .team-wrapper .team-item .item-image img {
      width: 100%;
      height: 100%;
      animation: reduce ease 0.5s forwards;
    }
    .team-wrapper .team-item .item-image:hover img {
      animation: amplify ease 0.5s forwards;
    }

    .team-wrapper .team-item p:nth-child(2) {
      width: 100%;
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      padding-top: 20px;
    }

    .team-wrapper .team-item p:nth-child(3) {
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 22px;
      opacity: 0.6;
    }
  }

  .brand-example {
    width: 100%;
    background: linear-gradient(rgba(#dfdfdf, 0.8), #ffffff);
    .brand {
      width: 830px;
      margin: 0 auto;
      padding-top: 80px;
      padding-bottom: 60px;
    }

    .brand > h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 45px;
      padding-bottom: 20px;
      text-align: center;
    }

    .brand > p {
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
    .image-wrapper {
      width: 100%;
      height: auto;
      margin-bottom: 80px;
      overflow: hidden;
    }
    .image-wrapper img {
      width: 100%;
    }
  }

  .brand-images {
    width: 100%;
    height: auto;
    text-align: center;
    background-color: #ffffff;
    .brand-wrapper {
      width: 100%;
      height: auto;
      margin: 180px 0 100px;
      > .image-wrapper {
        width: 100%;
        min-height: 719px;
      }
      > .image-wrapper img {
        width: 100%;
        height: 100%;
      }

      .brand-content {
        margin-top: 80px;
      }
      .brand-content > .image-wrapper {
        height: 252px;
        margin-bottom: 200px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .brand-content .brand-introduce {
        display: flex;
        flex-direction: row;
        margin-bottom: 200px;
      }
      .brand-content .brand-introduce .image-wrapper {
        width: 700px;
        height: 394px;
        cursor: pointer;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          animation: reduce ease 0.5s forwards;
        }
        &:hover img {
          animation: amplify ease 0.5s forwards;
        }
      }
      .brand-content .brand-introduce .introduce-desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          text-align: left;
        }
        > p:first-child {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 10px;
        }
        > p:last-child {
          font-size: 14px;
          padding-top: 10px;
        }
      }

      .brand-content .brand-introduce-left .introduce-desc {
        padding-left: 96px;
      }
      .brand-content .brand-introduce-right .introduce-desc {
        padding-right: 96px;
      }

      .brand-content-f7 .brand-introduce .introduce-desc {
        > p:nth-child(odd) {
          font-size: 16px;
        }
        > p:nth-child(even) {
          font-size: 32px;
          line-height: 38px;
          padding: 20px 0;
          font-family: "AalibabaPuHui-B";
        }
        > p:first-child {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .brand-content-f7 .brand-introduce:last-child {
        margin-bottom: 0;
      }
    }
    span {
      font-size: 32px;
      line-height: 45px;
      padding: 0 16px;
      font-weight: bold;
      text-align: center;
      position: relative;
      margin-bottom: 100px;
    }
    span::before {
      content: "";
      width: 200px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateX(-100%);
      background-color: #000000;
    }
    span::after {
      content: "";
      width: 200px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-left: 16px;
      transform: translateX(100%);
      background-color: #000000;
    }
    .brand-images-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 100px 100px 0;
    }

    .brand-images-content .left-wrapper .image-item,
    .brand-images-content .right-wrapper .image-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 120px;
      cursor: pointer;
      .image-wrapper {
        flex: 1;
        width: 100%;
        overflow: hidden;
        position: relative;
      }
      .image-wrapper img {
        width: 100%;
        height: 100%;
        animation: reduce ease 0.5s forwards;
      }
      .image-wrapper:hover img {
        animation: amplify ease 0.5s forwards;
      }
      .item-desc {
        height: 78px;
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .item-desc p:first-child {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .item-desc p:last-child {
        font-size: 14px;
        opacity: 0.6;
        text-align: left;
      }
    }

    .brand-images-content .left-wrapper {
      width: calc(50% - 60px);
      > p {
        width: 100%;
        height: 448px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0.6;
        padding: 0 60px;
        display: flex;
        align-items: center;
      }
      .image-item {
        height: 325px;
      }
      .image-item:nth-child(2) {
        height: 310px;
      }
      .image-item:nth-child(4) {
        height: 678px;
      }
    }

    .brand-images-content .right-wrapper {
      width: calc(50% - 60px);

      .image-item:nth-child(1) {
        height: 588px;
      }

      .image-item:nth-child(2) {
        height: 405px;
      }

      .image-item:nth-child(3) {
        height: 325px;
      }
      .image-item:nth-child(4) {
        height: 325px;
      }
      .image-item:nth-child(5) {
        height: 325px;
      }
    }
  }

  .brand-images-phone {
    display: none;
  }

  .contest-intro {
    width: 100%;
    position: relative;
    .image-wrapper {
      width: 100%;
      height: 740px;
      overflow: hidden;
    }
    .image-wrapper img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .image-wrapper img:nth-child(1) {
      width: 100%;
      position: relative;
    }
    .image-wrapper img:nth-child(2) {
      width: 800px;
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }
    .contest-intro-content {
      width: 820px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .contest-intro-content h1 {
      font-size: 32px;
      line-height: 45px;
      padding: 0 16px;
      font-weight: bold;
      text-align: center;
      position: relative;
    }
    .contest-intro-content h1::before {
      content: "";
      width: 120px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateX(-100%);
      background-color: #000000;
    }
    .contest-intro-content h1::after {
      content: "";
      width: 120px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-left: 16px;
      transform: translateX(100%);
      background-color: #000000;
    }

    .contest-intro-content p {
      font-size: 16px;
      line-height: 24px;
      padding-top: 24px;
      padding-bottom: 80px;
    }

    > button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 138px;
      z-index: 998;
      width: 380px;
      height: 59px;
      font-size: 20px;
      background: rgba(26, 26, 26, 0);
      border-radius: 54px 54px 54px 54px;
      border: 2px solid #ffffff;
    }
    > button:hover {
      background: rgba(26, 26, 26, 0.2);
    }
  }
}

// Mobile
@media screen and (max-width: 750px) {
  .containner {
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff;
  }

  .layout-header-pc {
    display: none !important;
  }

  .swiper {
    // height: 40vh;
    margin-top: 44px;
    position: relative;
    &:deep(.swiper-pagination) {
      width: 184px;
      position: absolute;
      left: 50%;
      bottom: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transform: translateX(-50%);
    }
    &:deep(.swiper-pagination) span {
      width: 50px;
      height: 3px;
      opacity: 0.5;
      border-radius: 0;
      background-color: #ffffff;
    }
    &:deep(.swiper-pagination) span.swiper-pagination-bullet-active {
      width: 50px;
      opacity: 1;
      border-radius: 0;
      background-color: #ffffff;
    }
  }
  .swiper-wrapper .swiper-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .swiper-wrapper .swiper-slide .loading {
    width: 100%;
    height: 100%;
    background-color: #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-wrapper .swiper-slide .loading img {
    width: 320px;
    height: auto;
    opacity: 0.2;
    animation: loading 1.2s ease-in infinite forwards;
  }
  .swiper-wrapper .swiper-slide:deep(img) {
    width: 50%;
    height: auto;
  }
  .swiper-wrapper .swiper-slide video {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  .we-story {
    height: auto;
    padding: 48px 0 0;
    display: flex;
    flex-direction: column;
    .story-content {
      flex: 4.3;
      padding: 0 20px;

      > p:nth-child(1) {
        font-size: 32px;
        line-height: 45px;
        font-weight: bold;
        padding-bottom: 16px;
      }

      > p:nth-child(2),
      > p:nth-child(3) {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
      }

      .QR-code {
        display: flex;
        flex-direction: row;

        .code-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          overflow: hidden;
          text-align: center;
        }

        .code-item img {
          width: 64px;
          height: 64px;
          margin-bottom: 4px;
        }

        .code-item span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .story-image {
      width: 100%;
      height: 210px;
      margin-top: 24px;
      img {
        //width: 100%;
        //height: 210px;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .we-team {
    width: 100%;
    height: auto;
    padding-top: 48px;
    background-color: #ffffff;

    .we-team-content {
      display: flex;
      flex-direction: column;
    }
    .we-team-content > .team-image {
      display: none;
    }
    .we-team-content > .our-team {
      padding: 0 24px 24px;
    }

    .we-team-content {
      > img {
        //height: 210px;
        //height: auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .our-team p:nth-child(1) {
        font-size: 32px;
        line-height: 45px;
        padding-bottom: 16px;
        font-weight: bold;
      }

      .our-team p:nth-child(2) {
        font-size: 14px;
        line-height: 22px;
      }
      .team-leader {
        padding: 24px;
      }
      .team-leader p:nth-child(1) {
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 12px;
        font-weight: bold;
      }

      .team-leader p:nth-child(2) {
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 26px;
        opacity: 0.6;
      }
    }

    .team-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 24px 22px;
    }

    .team-wrapper .team-wrapper-row-1,
    .team-wrapper .team-wrapper-row-2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .team-wrapper .team-item {
      width: 49%;
      //height: 156px;
      //height: auto;
      margin-bottom: 24px;
    }

    .team-wrapper .team-item .item-image {
      width: 100%;
      min-height: 78px;
      margin-bottom: 16px;
      background-color: #eaeaea;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .team-wrapper .team-item .item-image img {
      //width: 100%;
      //height: 100%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .team-wrapper .team-item p:nth-child(2) {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .team-wrapper .team-item p:nth-child(3) {
      font-size: 14px;
      line-height: 22px;
      opacity: 0.6;
    }
  }

  .brand-example {
    width: 100%;
    padding: 48px 20px;
    background: linear-gradient(rgba(#dfdfdf, 0.8), #ffffff);

    .brand > h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 45px;
      padding-bottom: 16px;
    }

    .brand > p {
      font-size: 14px;
    }

    .brand > span {
      font-size: 14px;
      line-height: 22px;
      opacity: 0.6;
    }
    .image-wrapper {
      width: 100%;
      height: auto;
      margin: 16px 0 0;
      overflow: hidden;
    }
    .image-wrapper img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .brand-images {
    display: none;
  }

  .brand-images-phone {
    width: 100%;
    height: auto;
    background-color: #fff;
    .brand-info {
      padding: 0 20px 48px;
    }
    .brand-wrapper {
      width: 100%;
    }
    .brand-wrapper .brand-content .image-wrapper {
      width: 100%;
      height: 80px;
      margin: 24px 0 48px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .brand-wrapper .brand-introduce {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 45px;
    }
    .brand-wrapper .brand-introduce .image-wrapper {
      width: 100%;
      height: 190px;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .brand-wrapper .brand-introduce .introduce-desc p:first-child {
      font-weight: bold;
      font-size: 16px;
    }
    .brand-wrapper .brand-introduce .introduce-desc p {
      font-size: 14px;
    }
    .brand-wrapper .brand-content-f7 .brand-introduce .introduce-desc p:nth-child(odd) {
      font-size: 14px;
    }
    .brand-wrapper .brand-content-f7 .brand-introduce .introduce-desc p:nth-child(even) {
      font-size: 24px;
      padding: 12px 0;
      font-family: 'Alibaba PuHuiTi';
    }
    .brand-wrapper .brand-content-f7 .brand-introduce .introduce-desc p:first-child {
      font-weight: bold;
      font-size: 16px;
    }
    .image-item {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
    }
    >.image-wrapper {
      //flex: 1;
      //height: 168px;
      width: 100%;
      min-height: 168px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .image-item .image-wrapper > img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .image-item .item-desc {
      width: 100%;
      height: 78px;
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .image-item .item-desc p:first-child {
      font-size: 16px;
      font-weight: bold;
    }

    .image-item .item-desc p:last-child {
      font-size: 14px;
      opacity: 0.6;
    }
  }

  .contest-intro {
    width: 100%;
    margin: 24px 0 0;
    position: relative;
    .image-wrapper {
      width: 100%;
      // height: 385px;
      overflow: hidden;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .image-wrapper img:nth-child(1) {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .image-wrapper img:nth-child(2) {
      width: calc(100% - 40px);
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%);
    }
    .contest-intro-content {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .contest-intro-content h1 {
      font-size: 18px;
      line-height: 45px;
      padding: 0 16px;
      font-weight: bold;
      text-align: center;
      position: relative;
    }
    .contest-intro-content h1::before {
      content: "";
      width: 60px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateX(-100%);
      background-color: #000000;
    }
    .contest-intro-content h1::after {
      content: "";
      width: 60px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-left: 16px;
      transform: translateX(100%);
      background-color: #000000;
    }

    .contest-intro-content p {
      font-size: 14px;
      line-height: 24px;
      padding-top: 24px;
      padding-bottom: 80px;
    }

    > button {
      width: 240px;
      height: 44px;
      background: rgba(26,26,26,0);
      border-radius: 54px 54px 54px 54px;
      border: 2px solid #FFFFFF;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 90px;
      z-index: 998;
    }
    > button:hover {
    background: rgba(26,26,26,.2);
    }
  }
}

.drawer-wrapper .drawer-content img {
  flex: 1;
  width: 100%;
}
.drawer-wrapper .drawer-content p:nth-child(2) {
  font-size: 32px;
  text-align: center;
  line-height: 45px;
}
.drawer-wrapper .drawer-content p:nth-child(3) {
  font-size: 16px;
  text-align: center;
  line-height: 45px;
}
</style>
