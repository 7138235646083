<template>
  <div>
    <header-phone
      :src="data.commonSrc && data.commonSrc[0]['extranetUrl']"
      :isGoBack="true"
    >
      <template v-slot:nav>
        <a @click="router.push('/')">{{ $t("HomePage") }}</a>
      </template></header-phone
    >
    <div class="contentBox">
      <h2>{{ data.detail.workName }}</h2>
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 1"
        src="@/assets/medal1.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 2"
        src="@/assets/medal2.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 3"
        src="@/assets/medal3.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 4"
        src="@/assets/medal4.png"
        alt=""
      />
      <img
        class="awards"
        style="width: 110px"
        v-if="data.detail.awardsSrc == 5"
        src="@/assets/medal5.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 31"
        src="@/assets/medal6.png"
        alt=""
      />
      <p>
        {{ data.detail.name }} | {{ data.detail.country
        }}{{ data.detail.category != "欧洲" ? " • " : ""
        }}{{ data.detail.category != "欧洲" ? data.detail.category : "" }}
      </p>
      <div class="video-content">
        <!-- <div class="vido-box-overflow"> -->
        <div class="video-item" v-for="(ele, i) in data.dataList" :key="i">
          <video-pc v-if="ele.isVideo != -1" :videoSrc="ele['extranetUrl']" />
          <img v-if="ele.isIMG != -1" :src="ele['extranetUrl']" alt="" />
          <img v-if="ele.isPNG != -1" :src="ele['extranetUrl']" alt="" />
          <img v-if="ele.isGif != -1" :src="ele['extranetUrl']" alt="" />
        </div>
        <div style="width: 100%; height: 84px; background: #fff"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { reactive, onMounted, onBeforeMount, ref } from "vue";
import API from "../api/index.js";
import videoPc from "@/components/video-PC.vue";
import headerPhone from "@/components/header-phone.vue";
const route = useRoute();
const router = useRouter();
const data = reactive({
  detail: {},
  dataList: [],
  commonSrc: null,
});
// const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));
data.detail = JSON.parse(route.query.detail);
onBeforeMount(() => {
  getList();
  fetchComonImage();
});
const getList = async () => {
  const res = await API.requestImage({
    fileDirUrl: `SAIC_Design/prod/${data.detail.annual}${data.detail.cloneSrc}/`,
  }).catch((e) => e);
  res.data.forEach((item) => {
    data.dataList.push({
      internalUrl: item["extranetUrl"],
      extranetUrl: item["extranetUrl"],
      isIMG: item["extranetUrl"].indexOf(".jpg"),
      isPNG: item["extranetUrl"].indexOf(".png"),
      isVideo: item["extranetUrl"].indexOf(".mp4"),
      isGif: item["extranetUrl"].indexOf("gif"),
    });
  });
};

const fetchComonImage = () => {
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).then((res) => {
    data.commonSrc = res.data;
  });
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 750px) {
  .layout-header-M {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    padding: 0 24px;
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    // transition: all 0.5s;
    .goBack {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .contentBox {
    width: 100%;
    padding: 6% 5% 0 5%;
    margin-top: 44px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
    }
    .awards {
      width: 82px;
      height: 28px;
      margin: 16px -8px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
    .video-content {
      margin-top: 20px;
      // position: relative;
      // width: 100%;
      // height: 0;
      // padding-bottom: 130%;
      .video-item {
        width: 100%;
        margin-bottom: 16px;
        img {
          // position: absolute;
          width: 100%;
          // height: 100%;
        }
      }
    }
  }
}
</style>
